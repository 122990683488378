
import { defineComponent } from 'vue'
import Editor from '@/components/Editor.vue'
import MaterialPanel from '@/components/MaterialPanel.vue'

export default defineComponent({
  name: 'DesignSpace',
  components: {
    MaterialPanel,
    Editor
  }
})
