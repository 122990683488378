<template>
  <div class="editor-propertites-panel tw-overflow-auto tw-relative tw-bg-white tw-border-l-2 tw-border-gray-400" style="width: 240px; min-width: 240px; max-width: 240px;flex-grow: 0;">
    <p v-show="!currentComponent">
      Click something in the canvas to edit.
    </p>

    <!-- 通过判断画布中当前选中的对象的类型加载相应的操作面板 -->
    <keep-alive>
      <component :is="currentComponent"></component>
    </keep-alive>
    <!-- /通过判断画布中当前选中的对象的类型加载相应的操作面板 -->

    <preview class="tw-absolute tw-w-auto tw-bottom-0" style="margin: 10px"></preview>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import ImageObject from '@/Designer/Features/Objects/ImageObject'
import EditorPropertiesPanelImage from '@/components/EditorPropertiesPanelImage.vue'
import EditorPropertiesPanelDefault from '@/components/EditorPropertiesPanelDefault.vue'
import Preview from '@/components/Preview.vue'

export default defineComponent({
  name: 'EditorPropertiesPanel',
  components: {
    EditorPropertiesPanelImage,
    EditorPropertiesPanelDefault,
    Preview
  },
  setup () {
    const store = useStore()
    const currentComponent = computed(() => {
      if (store.state.designer.currentObject instanceof ImageObject) {
        return 'EditorPropertiesPanelImage'
      }
      return 'EditorPropertiesPanelDefault'
    })

    return {
      currentComponent
    }
  }
})
</script>
