import { customizerLog } from '@/utils/helpers'
import { Canvas } from 'fabric/fabric-impl'
import { IEventManager } from './EventManager'
import { IImageObject } from './Objects/ImageObject'

export interface IObjectManager {
  eventManager: IEventManager;
  canvas: Canvas;
  objs: IImageObject[];
  addObj (obj: IImageObject): void;
  setObjects(objects: IImageObject[]): void;
  all (): IImageObject[];
  getIdx (obj: IImageObject): number;
  getActiveObj(): IImageObject | null;
  removeObj (obj: IImageObject): void;
  clear (): void;
  forward (obj: IImageObject): void;
  backward (obj: IImageObject): void;
  moveToBottom (obj: IImageObject): void;
  moveToUpper (obj: IImageObject): void;
  active (obj: string): void;
  toggleVisible (obj: string): void;
}

// fabric 新加的对象为push
// fabric 对象的层级为下标越高，层级越高
export default class ObjectsManager implements IObjectManager {
  eventManager: IEventManager;
  canvas: Canvas;
  objs: IImageObject[];

  /**
   * @param { fabric.Canvas } canvas
   * @param {EventManager} eventManager
   * @param { Object } objs
   *  < { Object } obj, {Number} scale,  >
   */
  constructor (canvas: Canvas, eventManager: IEventManager, objs: IImageObject[] = []) {
    this.eventManager = eventManager
    this.canvas = canvas
    this.objs = objs
  }

  getActiveObj (): IImageObject | null {
    const activeObj = this.canvas.getActiveObject()
    return this.objs.find(obj => obj.obj === activeObj)
  }

  addObj (obj: IImageObject): void {
    this.objs.push(obj)
    this.canvas.add(obj.obj)
    this.canvas.setActiveObject(obj.obj)
    this.canvas.renderAll()
    this.eventManager.trigger('Playground.objectAdded', obj)
  }

  active (id: string): void {
    const target = this.find(id)
    this.canvas.setActiveObject(target.obj)
    this.canvas.renderAll()
    this.eventManager.trigger('Playground.objectSelected', target)
  }

  setObjects (objects: IImageObject[]): void {
    objects.forEach((obj) => this.addObj(obj))
  }

  getIdx (obj: IImageObject): number {
    const target = this.objs.find(o => o.id === obj.id)
    return this.objs.indexOf(target)
  }

  removeObj (obj: IImageObject): void {
    this.objs.splice(this.getIdx(obj), 1)
    this.canvas.remove(obj.obj)
    this.canvas.renderAll()
    customizerLog('object removed')
    this.eventManager.trigger('Playground.objectRemoved', obj)
  }

  clear (): void {
    this.all().forEach((obj) => {
      this.removeObj(obj)
    })
  }

  all (): IImageObject[] {
    return this.objs
  }

  forward (obj: IImageObject): void {
    if (!obj) {
      return
    }
    const idx = this.getIdx(obj)
    customizerLog('operation: forward', obj, idx)
    if (idx + 1 === this.objs.length) {
      customizerLog('it is already on the top')
      return
    }
    this.canvas.bringForward(obj.obj)
    this.objs.splice(idx, 1)
    this.objs.splice(idx + 1, 0, obj)
    this.eventManager.trigger('Playground.layerOrderChanged')
  }

  backward (obj: IImageObject): void {
    if (!obj) {
      return
    }
    const idx = this.getIdx(obj)
    if (idx === 0) {
      customizerLog('it is already on the bottom')
      return
    }
    this.canvas.sendBackwards(obj.obj)
    this.objs.splice(idx, 1)
    this.objs.splice(idx - 1, 0, obj)
    this.eventManager.trigger('Playground.layerOrderChanged')
  }

  moveToBottom (obj: IImageObject): void {
    if (!obj) {
      return
    }
    const idx = this.getIdx(obj)
    if (idx === 0) {
      return
    }
    this.canvas.sendToBack(obj.obj)
    this.objs.splice(idx, 1)
    this.objs.push(obj)
  }

  moveToUpper (obj: IImageObject): void {
    if (!obj) {
      return
    }
    const idx = this.getIdx(obj)
    if (idx + 1 === this.objs.length) {
      return
    }
    this.canvas.bringToFront(obj.obj)
    this.objs.splice(idx)
    this.objs.unshift(obj)
  }

  toggleVisible (id: string): void {
    const target = this.find(id)
    if (target.obj.visible && this.canvas.getActiveObject() === target.obj) {
      this.canvas.discardActiveObject()
    }
    target.obj.set({
      visible: !target.obj.visible
    })
    this.canvas.renderAll()
  }

  protected find (id: string): IImageObject | null {
    return this.objs.find(o => o.id === id)
  }
}
