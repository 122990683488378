<template>
  <div class="tw-text-gray-100 ">
    <p class="tw-font-normal tw-text-lg tw-border-b-1 tw-border-gray-400">Layers</p>

    <div class="tw-flex" v-for="object in objects.slice().reverse()" :key="object.id">
      <div class="tw-flex tw-items-center tw-w-full tw-p-2 tw-relative" @click="activeObject(object)">
        <img :src="$process(object.meta.sourceUrl, { width: 30 })" alt="">
        <p class="tw-ml-2 tw-truncate">{{ object.meta.name }}</p>
        <button class="tw-pl-2 tw-absolute tw-right-0" @click="toggleVisible(object)">
          <svg t="1602526635929" class="tw-h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="30974"><path d="M956.8 496c-41.6-70.4-99.2-147.2-176-204.8l105.6-105.6c12.8-12.8 12.8-32 0-44.8s-32-12.8-44.8 0l-115.2 115.2C665.6 214.4 592 192 512 192 297.6 192 153.6 358.4 67.2 496c-6.4 9.6-6.4 22.4 0 32 41.6 70.4 102.4 147.2 176 204.8l-108.8 108.8c-12.8 12.8-12.8 32 0 44.8C144 892.8 150.4 896 160 896s16-3.2 22.4-9.6l115.2-115.2c60.8 38.4 134.4 60.8 214.4 60.8 185.6 0 374.4-128 444.8-307.2C960 515.2 960 505.6 956.8 496zM134.4 512c76.8-121.6 201.6-256 377.6-256 60.8 0 118.4 16 166.4 44.8l-80 80C576 361.6 544 352 512 352c-89.6 0-160 70.4-160 160 0 32 9.6 64 25.6 89.6l-89.6 89.6C224 640 172.8 572.8 134.4 512zM608 512c0 54.4-41.6 96-96 96-16 0-28.8-3.2-41.6-9.6l128-128C604.8 483.2 608 496 608 512zM416 512c0-54.4 41.6-96 96-96 16 0 28.8 3.2 41.6 9.6l-128 128C419.2 540.8 416 528 416 512zM512 768c-60.8 0-118.4-16-166.4-44.8l80-80C448 662.4 480 672 512 672c89.6 0 160-70.4 160-160 0-32-9.6-64-25.6-89.6l89.6-89.6c67.2 51.2 118.4 118.4 156.8 179.2C825.6 659.2 665.6 768 512 768z" p-id="30975" fill="#ffffff"></path></svg>
        </button>
      </div>
    </div>

    <div class="tw-flex tw-justify-between tw-items-center">
      <p>Background</p>
      <div class="tw-flex tw-items-center tw-h-5">
        <div class="tw-w-10 tw-h-5" :style="{'background-color': color}"></div>
        <button class="tw-ml-3" @click="editColor">
          <svg t="1602528175138" class="tw-h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="33207" ><path d="M1009.5 192.5 832 14.9c-9.8-9.8-27.2-8.3-38.9 3.4l-84.6 84.6-36.8 36.8 35.5 35.5 142 142.1 35.5 35.5 36.8-36.8 84.6-84.6C1017.8 219.7 1019.3 202.3 1009.5 192.5zM890 276.5 747.9 134.5 809 73.4l142.1 142.1L890 276.5z" p-id="33208" fill="#ffffff"></path><path d="M666.4 215.9l-35.5-35.5-49.3 49.3L137.5 673.8 116.4 695l-0.4 0.4c-3.8 3.8-6.7 8.4-8.4 13.4L19.8 974.2c-3.2 9.6-7.8 25.8-1.5 32 6.3 6.2 22.4 1.7 32-1.5l265.4-87.9c4.9-1.6 9.6-4.5 13.4-8.3l0.4-0.5 21.2-21.1 444.1-444.1 49.3-49.3L808.5 358 666.4 215.9zM92.4 932 160 727.8l136.6 136.6L92.4 932zM177.7 704.7l448-448 142 142.1-448 448L177.7 704.7z" p-id="33209" fill="#ffffff"></path></svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { IImageObject } from '@/Designer/Features/Objects/ImageObject'
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'MaterialPanelContentLayers',
  emits: ['edit-color'],
  setup (props, { emit }) {
    const store = useStore()

    const objects = computed(() => {
      return store.state.designer.objects as IImageObject[]
    })

    const color = computed(() => {
      return store.state.designer.currentColor
    })

    const activeObject = ({ id }) => {
      store.dispatch('designer/activeObject', id)
    }

    const toggleVisible = ({ id }) => {
      store.dispatch('designer/toggleObjectVisible', id)
    }

    const editColor = () => {
      emit('edit-color')
    }

    return {
      editColor,
      color,
      objects,
      activeObject,
      toggleVisible
    }
  }
})
</script>
