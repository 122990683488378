
import { Mockup } from '@/store/moduleDesign'
import { defineComponent, PropType, watchEffect, ref } from 'vue'
import ImageProgress from '@/components/ImageProgress.vue'

export default defineComponent({
  name: 'PreviewFullscreenList',
  props: {
    images: {
      required: true,
      type: Array as PropType<Mockup[]>
    }
  },
  components: {
    ImageProgress
  },
  setup(props) {
    const currentImageUrl = ref('')

    watchEffect(() => {
      currentImageUrl.value = props.images.length ? props.images[0].url : ''
    })

    const setcurrentImageUrl = (image: string) => {
      currentImageUrl.value = image
    }

    return {
      currentImageUrl,
      setcurrentImageUrl
    }
  }
})
