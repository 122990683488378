
import useToolbarOperations from '@/hooks/useToolbarOperations'
import PreviewFullscreen from '@/components/PreviewFullscreen.vue'
import TemplatePreview from '@/components/TemplatePreview.vue'
import { defineComponent, computed, ref } from 'vue'
import { useStore } from 'vuex'
import useGenerationProgress from '@/hooks/useGenerationProgress'

export default defineComponent({
  name: 'Toolbar',

  components: { TemplatePreview, PreviewFullscreen },

  setup () {
    const store = useStore()

    const isDesignEdited = computed(() => {
      return store.state.status.isDesignEdited
    })

    const toggleGridlines = () => {
      store.dispatch('designer/toggleGridlines')
    }

    const previewVisible = ref(false)

    const saveDesign = (degree: number) => {
      store.dispatch('designer/completeDesign', degree)
      previewVisible.value = false
    }

    const { degrees } = useGenerationProgress()

    const done = () => {
      if (degrees.value.length < 2) {
        store.dispatch('designer/completeDesign')
        previewVisible.value = false
      } else {
        previewVisible.value = true
      }
    }

    return {
      degrees,
      previewVisible,
      done,
      saveDesign,
      isDesignEdited,
      toggleGridlines,
      ...useToolbarOperations()
    }
  }
})
