<template>
  <div class="background-container">
    <div class="background-toolbar tw-flex">
      <button class="tw-w-1/2 tw-h-10 tw-bg-gray-300" @click="clearColor">Clear</button>
      <button class="tw-w-1/2 tw-h-10 tw-bg-gray-300" @click="$emit('close')">Done</button>
    </div>

    <div class=" tw-flex tw-items-center tw-overflow-x-scroll tw-m-2">
      <v-swatches
      v-model="color"
      show-fallback
      fallback-input-type="color"
      inline
      :wrapperStyle="{'margin-left': 0, display: 'flex' }"
      :clearable="false"
      background-color="none"
      popover-x="right"
      popover-y="top"
      color-picker-color="#333"
      ref="refSwatches"
    ></v-swatches>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'
import VSwatches from '@/components/color-picker/VSwatches.vue'
import 'vue-swatches/dist/vue-swatches.css'

export default defineComponent({
  name: 'MobileBackground',
  components: {
    VSwatches
  },
  emits: ['close'],
  setup () {
    const color = ref('')

    const store = useStore()

    watch(color, () => {
      store.dispatch('designer/setBackgroundColor', color.value)
    })

    const refSwatches = ref(null)
    const clearColor = () => {
      refSwatches.value.clearColor()
    }

    return {
      color,
      refSwatches,
      clearColor
    }
  }
})
</script>

<style lang="postcss">
.background-container {
  height: calc(100vh - 100vw);
}
/* .vue-swatches__wrapper {
  display: flex;
} */
</style>
