<template>
<div class="tw-relative tw-max-w-sm tw-rounded tw-overflow-hidden tw-shadow-lg tw-pb-4">
  <image-progress :image="image" :list="downloadItems" ></image-progress>
  <img class="tw-w-full tw-h-64 tw-object-contain tw-cursor-pointer" :src="$artworkProcess(image.url, image.key, 500)" alt="Sunset in the mountains" @click="onImageClicked">

  <div class="item-details tw-px-3 tw-mt-2">
    <div class="tw-truncate tw-font-semibold tw-cursor-pointer" @click="onImageClicked">
        {{ image.name }}
    </div>
    <div class="tw-mt-1 tw-font-light tw-text-sm">
        Type: {{ image.ext }}
    </div>
    <div class="tw-mt-1 tw-font-light tw-text-sm tw-truncate">
        Resolution: {{ image.width }} x {{ image.height }}
    </div>
    <div class="tw-my-1 tw-font-light tw-text-sm">Size: {{ mb(image.size) }} MB</div>
    <div class="tw-mt-1 tw-font-light tw-text-sm">
        Date created: {{ image.createdAt }}
    </div>
  </div>
  <div class="tw-flex tw-justify-end">
    <button class="tw-btn-sm tw-btn-red tw-mr-2" @click="deleteImage">Delete</button>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import ImageProgress from '@/components/ImageProgress.vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'ImageCard',
  props: ['image'],
  components: {
    ImageProgress
  },
  emits: ['on-delete', 'on-click'],
  setup (props, { emit }) {
    const store = useStore()

    const downloadItems = computed(() => {
      return store.state.status.download.pool
    })

    const deleteImage = () => {
      emit('on-delete')
    }

    const mb = (size) => {
      return (size / 1024 / 1024).toFixed(2)
    }

    const onImageClicked = () => {
      emit('on-click')
    }

    return {
      downloadItems,
      deleteImage,
      mb,
      onImageClicked
    }
  }
})
</script>
