import { fabric } from 'fabric'
import { debounce } from 'lodash-es'
import Designer, { IDesigner } from '@/Designer'
import EventManager, { IEventManager } from '@/Designer/Features/EventManager'
import ObjectsManager from '@/Designer/Features/ObjectsManager'
import Playground, { IPlayground } from '@/Designer/Features/Playground'
import SidesManager from '@/Designer/Features/SidesManager'
import { Canvas } from 'fabric/fabric-impl'
import { customizerLog, isPhone } from '@/utils/helpers'
import { nextTick } from 'vue'
// import AutoSave from '@/Designer/Features/AutoSave'

export interface CanvasOpts {
  el: string;
  height: number;
  width: number;
  resizeParams: {
    el: string;
    fixedWidth: number;
    fixedHeight: number;
  };
}

function setBackground (canvas: Canvas): void {
  canvas.backgroundColor = new fabric.Pattern({
    source:
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg=='
  })
  // 需要刷新才能显示
  window.setTimeout(() => {
    canvas.renderAll()
  }, 300)
}

/**
 * bridge fabricjs events with EventManager
 * @param context
 * @param eventManager
 * @param canvas
 */
function bridgeFabricEvents (context, eventManager: IEventManager, canvas: Canvas): void {
  const events = {
    onObjectModified: () => {
      eventManager.trigger('Objects.objectModified')
    },
    onObjectAdded: () => {
      customizerLog('onObjectAdded')
    },
    onOverlayImageChanged: () => {
      customizerLog('onOverlayImageChanged')
    },
    onSelectionCreated: e => {
      customizerLog('onSelectionCreated', e)
      context.dispatch('setCurrentObject')
    },
    onSelectionCleared: e => {
      customizerLog('onSelectionCleared', e)
      context.dispatch('setCurrentObject', null)
    },
    onSelectionUpdated: e => {
      customizerLog('onSelectionUpdated', e)
      eventManager.trigger('Playground.objectSelected')
    }
  }

  // 使用 EventManager 代理 fabric 的事件回调
  canvas.on('object:modified', events.onObjectModified)
  canvas.on('object:added', events.onObjectAdded)
  canvas.on('selection:created', events.onSelectionCreated)
  canvas.on('selection:cleared', events.onSelectionCleared)
  canvas.on('selection:updated', events.onSelectionUpdated)
}

function handleResizeEvent (canvas: Canvas, newWidth: number, eventManager): void {
  const oldWidth = canvas.width
  const ratio = newWidth / oldWidth

  // resize canvas, overlay image
  canvas.setWidth(newWidth).setHeight(newWidth)

  customizerLog(`resize-canvas-event: resizing, from ${oldWidth} to ${newWidth} with ration ${ratio}`)

  const overlayImage = canvas.overlayImage
  if (overlayImage) {
    overlayImage.scaleToWidth(newWidth).setCoords()
    overlayImage.top = overlayImage.top * ratio
    overlayImage.left = overlayImage.left * ratio
  }

  // resize objects
  canvas.getObjects().forEach(obj => {
    obj.scaleX = obj.scaleX * ratio
    obj.scaleY = obj.scaleY * ratio
    obj.top = obj.top * ratio
    obj.left = obj.left * ratio
    obj.setCoords()
  })

  canvas.renderAll()

  eventManager.trigger('Playground.diemensionChanged', { width: newWidth, height: newWidth })
}

function registerResizeListener (canvas: Canvas, { fixedWidth, fixedHeight }, eventManager): void {
  if (!isPhone()) {
    const collapseMaterialBtn = document.querySelector('.material-panel-collapse-btn')

    collapseMaterialBtn.addEventListener('click', () => {
      nextTick(() => {
        // 自适应规则：将浏览器窗口大小减去左右两侧固定栏宽度
        const MATERIAL_PANEL_DEFAULT_WIDTH = 425
        const materialPanel = document.querySelector('.material-panels') as HTMLElement
        const width = window.innerWidth - fixedWidth + (MATERIAL_PANEL_DEFAULT_WIDTH - materialPanel.offsetWidth)
        const height = window.innerHeight - fixedHeight
        if (width <= 300 || height <= 300) {
          customizerLog('resize-canvas-event: break, width, height can not be less than 0')
          return
        }
        handleResizeEvent(canvas, Math.min(width, height), eventManager)
      })
    })

    window.addEventListener(
      'resize',
      debounce(() => {
        // 自适应规则：将浏览器窗口大小减去左右两侧固定栏宽度
        const width = window.innerWidth - fixedWidth
        const height = window.innerHeight - fixedHeight
        if (width <= 300 || height <= 300) {
          customizerLog('resize-canvas-event: break, width, height can not be less than 300')
          return
        }
        handleResizeEvent(canvas, Math.min(width, height), eventManager)
      }, 300),
      false
    )
  }
}

function newCanvas (args: CanvasOpts): Canvas {
  // 隐藏四边中间的控制点
  fabric.Object.prototype.setControlsVisibility({
    tl: true, // top-left
    mt: false, // middle-top
    tr: true, // top-right
    ml: false, // middle-left
    mr: false, // middle-right
    bl: true, // bottom-left
    mb: false, // middle-bottom
    br: true // bottom-right
  })

  const opts = {
    visible: true,
    width: args.width < 300 ? 300 : args.width,
    height: args.height < 300 ? 300 : args.height,
    controlsAboveOverlay: true,
    preserveObjectStacking: true
  }

  return new fabric.Canvas(args.el, opts)
}

function newPlayground (context, params: CanvasOpts, eventManager: IEventManager): IPlayground {
  const canvas = newCanvas(params)

  setBackground(canvas)

  registerResizeListener(canvas, params.resizeParams, eventManager)

  bridgeFabricEvents(context, eventManager, canvas)

  return new Playground(canvas, eventManager)
}

export default function (context): IDesigner {
  // tabs, tab-content, editor-view-margin, properties
  const fixedWidth = isPhone() ? 0 : 75 + 350 + 24 + 24 + 240
  // toolbar, editor-view-margin
  const fixedHeight = isPhone() ? 0 : 48 + 24 + 24

  const calculateCanvasWidth = () => {
    const width = window.innerWidth - fixedWidth
    const height = window.innerHeight - fixedHeight

    return Math.min(width, height)
  }

  const params: CanvasOpts = {
    el: 'design-canvas',
    height: calculateCanvasWidth(),
    width: calculateCanvasWidth(),
    resizeParams: {
      el: 'design-area__container',
      fixedWidth,
      fixedHeight
    }
  }

  const eventManager = new EventManager()

  const playground = newPlayground(context, params, eventManager)

  const objectsManager = new ObjectsManager(playground.canvas, eventManager)

  const sidesManager = new SidesManager(eventManager)

  const designer = new Designer().init(eventManager, playground, objectsManager, sidesManager)

  // TODO: web worker
  // const autoSaver = new AutoSave(designer, context)

  // eventManager.observerd(autoSaver)

  eventManager.register('Playground.objectAdded', async () => {
    await context.dispatch('setObjects')
    context.dispatch('status/recordDesignEdited', null, { root: true })
    context.dispatch('designer/calculatePrintQuality', null, { root: true })
  })

  eventManager.register('Playground.objectSelected', async () => {
    await context.dispatch('setCurrentObject')
    context.dispatch('designer/calculatePrintQuality', null, { root: true })
  })

  eventManager.register('Playground.layerOrderChanged', () => {
    context.dispatch('setObjects')
    context.dispatch('status/recordDesignEdited', null, { root: true })
  })

  eventManager.register('Playground.objectRemoved', () => {
    context.dispatch('setObjects')
    context.dispatch('status/recordDesignEdited', null, { root: true })
  })

  eventManager.register('Playground.cleared', () => {
    context.dispatch('setObjects')
    context.dispatch('setBackgroundColor', '')
    objectsManager.clear()
    context.dispatch('clearObjects')
    context.dispatch('status/recordDesignEdited', null, { root: true })
  })

  eventManager.register('Objects.objectModified', () => {
    context.dispatch('status/recordDesignEdited', null, { root: true })
    context.dispatch('designer/calculatePrintQuality', null, { root: true })
  })

  eventManager.register('Playground.diemensionChanged', ({ width, height }) => {
    const currentSide = sidesManager.getCurrent()
    currentSide.canvasWidth = width
    currentSide.canvasHeight = height
  })

  window.designer = designer
  window.canvas = designer.playground.canvas

  return designer
}
