import { imageProcess } from './image-process'
type Size = 500 | 1000 | 100

export type ArtworkProcess = (url: string, key: string, size: Size) => string;

export const artworkProcess: ArtworkProcess = (url: string, key: string, size: Size) => {
  // @TODO: remove
  if (!url.match('artworks')) {
    return imageProcess(url, { width: size })
  }

  const thumbKey = key.replace('artworks/', `artwork-thumbs/l_${size}/`)
  return url.replace(key, thumbKey)
}
