<template>
  <div>
    <button class="tw-border-1 tw-border-gray-500 tw-mr-4" @click="flip('X')">
      <svg
        t="1602558366839"
        class="fill-current"
        :class="size === 'sm' ? 'tw-w-5 tw-h-5' : 'tw-w-10 tw-h-10'"
        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3043"><path d="M159.530667 810.666667H362.666667a21.333333 21.333333 0 0 0 21.333333-21.333334V281.429333a21.333333 21.333333 0 0 0-41.130667-7.893333L139.690667 781.397333A21.333333 21.333333 0 0 0 159.573333 810.666667z" fill="#000000" opacity=".3" p-id="3044"
      ></path><path d="M864.469333 810.666667H661.333333a21.333333 21.333333 0 0 1-21.333333-21.333334V281.429333a21.333333 21.333333 0 0 1 41.130667-7.893333l203.178666 507.861333a21.333333 21.333333 0 0 1-19.84 29.269334z" fill="#000000" p-id="3045"></path><path d="M469.333333 85.333333m42.666667 0l0 0q42.666667 0 42.666667 42.666667l0 768q0 42.666667-42.666667 42.666667l0 0q-42.666667 0-42.666667-42.666667l0-768q0-42.666667 42.666667-42.666667Z" fill="#000000" opacity=".3" p-id="3046"></path></svg>
    </button>
    <button class="tw-border-1 tw-border-gray-500" @click="flip('Y')">
      <svg
        t="1602558382654"
        class="fill-current"
        :class="size === 'sm' ? 'tw-w-5 tw-h-5' : 'tw-w-10 tw-h-10'"
        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3222"
      ><path d="M213.333333 159.530667V362.666667a21.333333 21.333333 0 0 0 21.333334 21.333333h507.904a21.333333 21.333333 0 0 0 7.893333-41.130667L242.602667 139.690667A21.333333 21.333333 0 0 0 213.333333 159.573333z" fill="#000000" opacity=".3" p-id="3223"></path><path d="M213.333333 864.469333V661.333333a21.333333 21.333333 0 0 1 21.333334-21.333333h507.904a21.333333 21.333333 0 0 1 7.893333 41.130667L242.602667 884.309333A21.333333 21.333333 0 0 1 213.333333 864.426667z" fill="#000000" p-id="3224"></path><path d="M938.666667 469.333333m0 42.666667l0 0q0 42.666667-42.666667 42.666667l-768 0q-42.666667 0-42.666667-42.666667l0 0q0-42.666667 42.666667-42.666667l768 0q42.666667 0 42.666667 42.666667Z" fill="#000000" opacity=".3" p-id="3225"></path></svg>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import useImageOperation from '@/hooks/useImageOperations'

export default defineComponent({
  name: 'ImageOperationFlip',
  props: {
    size: {
      type: String,
      rquired: false,
      default: 'sm'
    }
  },
  setup () {
    return {
      ...useImageOperation()
    }
  }
})
</script>
