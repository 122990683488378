import { IDesigner } from '@/Designer'
import { BluePrintData, DesignModelData } from '@/Designer/Features/SidesManager'

function initSides (designer: IDesigner, bluePrints: BluePrintData[]): void {
  const sides = bluePrints.map((area, idx) => {
    return {
      id: area.id,
      name: area.name,
      guideLine: area.guideLine,
      config: area.config,
      backgroundColor: '',
      canvasWidth: designer.playground.canvas.width,
      canvasHeight: designer.playground.canvas.height,
      objects: [],
      active: !idx
    }
  })

  designer.sidesManager.setSides(sides)
}

function activeSide (designer: IDesigner, side: BluePrintData): void {
  designer.sidesManager.active(side.id)
}

export default function initDesignModelConfig (designer: IDesigner, data: DesignModelData): void {
  initSides(designer, data.bluePrints)
  activeSide(designer, data.bluePrints[0])
  designer.playground.setGuidelines(designer.sidesManager.getCurrent().guideLine)
}
