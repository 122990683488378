<template>
  <div
    class="image-progress-container tw-w-full tw-bg-gray-400"
    v-if="progress"
  >
    <div
      class="image-progress__overlay tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-bg-gray-100 tw-bg-opacity-75"
    ></div>
    <div
      v-if="showProgressBar"
      class="image-progress__content tw-absolute tw-top-0 tw-left-0 tw-w-full tw-p-2 tw-pt-8"
    >
      <div
        class="tw-flex tw-relative tw-mb-2 tw-items-center tw-justify-between"
      >
        <div class="tw-text-right">
          <span
            class="tw-text-xs tw-font-semibold tw-inline-block tw-text-pink-600"
          >
            <span>
              {{ title }}
            </span>
            <span> {{ progress }}% </span>
          </span>
        </div>
      </div>
      <div
        class="tw-overflow-hidden tw-h-2 tw-mb-4 tw-text-xs tw-flex tw-rounded tw-bg-pink-200"
      >
        <div
          :style="{ width: progress + '%' }"
          class="tw-shadow-none tw-flex tw-flex-col tw-text-center tw-whitespace-nowrap tw-text-white tw-justify-center tw-bg-pink-500"
        ></div>
      </div>
    </div>
    <div
      class="image-progress__content tw-text-xs tw-font-semibold tw-text-pink-600 tw-absolute tw-bottom-0 tw-right-0 tw-top-0 tw-left-0 tw-flex tw-justify-center tw-items-center"
      v-else
    >
      {{ title }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { ImageData } from '@/store/funcs/addImage'

type ProgressImage = {
  id: number;
  progress: number;
}

export default defineComponent({
  name: 'ImageProgress',
  props: {
    title: {
      required: false,
      type: String,
      default: 'Download'
    },
    image: {
      required: true,
      type: Object as PropType<ImageData>
    },
    list: {
      required: true,
      type: Array as PropType<ProgressImage[]>
    },
    showProgressBar: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const progress = computed(() => {
      const target = props.list.find(item => item.id === props.image.id)
      if (target) {
        return target.progress
      }
      return 0
    })

    return {
      progress
    }
  }
})
</script>
