<template>
  <teleport to="#fullscreen-container">
    <div
      class="fullscreen-modal tw-z-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center"
    >
      <div
        class="modal-overlay tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-bg-gray-100"
      ></div>

      <div
        class="wow-modal-container tw-fixed tw-w-full tw-h-full tw-z-50 tw-overflow-y-auto"
        @click="close"
      >
        <!-- close btn -->
        <div
          class="modal-close tw-absolute tw-top-0 tw-right-0 tw-cursor-pointer tw-flex tw-flex-col tw-items-center tw-mt-4 tw-mr-4 tw-text-black tw-text-sm tw-z-50"
          @click="close"
        >
          <svg
            class="fill-current tw-text-black"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            ></path>
          </svg>
          (Esc)
        </div>
        <!-- /close btn -->

        <!-- modal content -->
        <div
          class="wow-modal-content tw-container tw-mx-auto tw-mt-10 tw-h-auto tw-text-left tw-p-4 tw-bg-white"
        >

          <div style="height: 50px">
            <preview-generation-progress v-show="isGenerating" ></preview-generation-progress>
          </div>

          <div v-if="degrees.length > 1 && !isGenerating">
            <div v-if="degreeChooseable">
              <p class=" tw-text-center tw-text-lg">
                Please choose the intensity of blending
              </p>
            </div>

            <div class="tw-flex tw-items-center tw-justify-between">

              <div class="tw-flex tw-items-center">
                <p>The intensity of blending:</p>
                <div>
                  <button
                    class="tw-btn tw-px-2"
                    :class="degree === d ? 'tw-text-red-500' : 'tw-text-blue-500'"
                    v-for="d in degrees" :key="d" @click="tweakDegree(d)"
                  >{{d}}</button>
                </div>
              </div>

              <button v-if="degreeChooseable" class="tw-btn tw-btn-blue" @click="saveDesign">Save</button>

            </div>

            <p class="tw-text-red-500 tw-font-light tw-italic tw-text-sm">
              *It will only affect the display of renderings, and will not have any effect on the production of printed products.
            </p>

          </div>

          <preview-fullscreen-list :images="imageGroup"></preview-fullscreen-list>

        </div>
        <!-- /modal content -->
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect } from 'vue'
import PreviewGenerationProgress from '@/components/PreviewGenerationProgress.vue'
import useGenerationProgress from '@/hooks/useGenerationProgress'
import useEscListener from '@/hooks/useEscListener'
import PreviewFullscreenList from '@/components/PreviewFullscreenList.vue'
import { Mockup } from '@/store/moduleDesign'

export default defineComponent({
  name: 'PreviewFullscreen',
  props: {
    degreeChooseable: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  components: {
    PreviewGenerationProgress,
    PreviewFullscreenList
  },
  emits: ['close', 'save-design'],
  setup(props, { emit }) {
    const { close } = useEscListener(emit)

    const { imageGroups, degrees, isDesignEdited, progress, isGenerating, generateMockups } = useGenerationProgress()

    const imageGroup = ref<Mockup[]>([])

    const degree = ref<number|undefined>(1)

    const tweakDegree = (d: number) => {
      imageGroup.value = imageGroups.value[d]
      degree.value = d
    }

    watchEffect(() => {
      const groupKeys = Object.keys(imageGroups.value)
      imageGroup.value = groupKeys.length > 0 ? imageGroups.value[groupKeys[0]] : []
    })

    const saveDesign = () => {
      emit('save-design', degree.value)
    }

    return {
      saveDesign,
      degree,
      imageGroup,
      tweakDegree,
      degrees,
      imageGroups,
      isDesignEdited,
      progress,
      isGenerating,
      generateMockups,
      close
    }
  }
})
</script>

<style lang="postcss" scoped>
.fullscreen-modal {
  transition: opacity 0.25s ease;
}
.thumbs-container {
  width: 90px;
}
.wow-modal-content {
  max-width: 1200px;
}
</style>
