
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import ImageObject from '@/Designer/Features/Objects/ImageObject'
import MobileEditorPropertiesPanelDefault from '@/components/mobile/MobileEditorPropertiesPanelDefault.vue'
import MobileEditorPropertiesPanelImage from '@/components/mobile/MobileEditorPropertiesPanelImage.vue'

export default defineComponent({
  name: 'MobileEditorPropertiesPanel',
  components: {
    MobileEditorPropertiesPanelImage,
    MobileEditorPropertiesPanelDefault
  },
  setup () {
    const store = useStore()
    const currentComponent = computed(() => {
      if (store.state.designer.currentObject instanceof ImageObject) {
        return 'MobileEditorPropertiesPanelImage'
      }
      return 'MobileEditorPropertiesPanelDefault'
    })

    return {
      currentComponent
    }
  }
})
