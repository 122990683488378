<template>
  <div>
      <div class="tw-relative tw-pt-1 tw-w-full tw-px-2">
          <div v-if="showTitle" class="tw-flex tw-mb-2 tw-items-center tw-justify-between">
              <div>
                <span class="tw-text-xs tw-font-semibold tw-inline-block tw-py-1 tw-px-1 tw-uppercase tw-rounded-full tw-text-pink-600 tw-bg-pink-200">
                    Generating...
                </span>
              </div>
              <div class="tw-text-right">
                <span class="tw-text-xs tw-font-semibold tw-inline-block tw-text-pink-600">
                    {{ progress }}%
                </span>
              </div>
          </div>
          <div class="tw-overflow-hidden tw-h-2 tw-text-xs tw-flex tw-rounded tw-bg-pink-200">
              <div :style="{ width: progress+'%'}" class="tw-shadow-none tw-flex tw-flex-col tw-text-center tw-whitespace-nowrap tw-text-white tw-justify-center tw-bg-pink-500"></div>
          </div>
      </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import useGenerationProgress from '@/hooks/useGenerationProgress'

export default defineComponent({
  name: 'PreviewGenerationProgress',
  props: {
    showTitle: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  setup () {
    return {
      ...useGenerationProgress()
    }
  }
})
</script>
