
import { defineComponent, ref } from 'vue'
import MobilePreview from '@/components/mobile/MobilePreview.vue'
import TemplatePreview from '@/components/TemplatePreview.vue'
import useGenerationProgress from '@/hooks/useGenerationProgress'
import useToolbarOperations from '@/hooks/useToolbarOperations'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'MobileToolbar',
  components: {
    MobilePreview,
    TemplatePreview
  },
  setup () {
    const preview = ref(false)

    const { clear, templateVisible, displayTemplate } = useToolbarOperations()

    const { generateMockups } = useGenerationProgress()

    const previewMockups = () => {
      generateMockups(2)
      preview.value = true
    }

    const store = useStore()

    const done = () => {
      store.dispatch('designer/completeDesign')
    }

    return {
      done,
      clear,
      preview,
      previewMockups,
      generateMockups,
      templateVisible,
      displayTemplate
    }
  }
})
