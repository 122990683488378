<template>
  <div>
    <div
      class="tw-bg-white tw-px-4 tw-py-3 tw-flex tw-items-center tw-justify-between tw-border-t tw-border-gray-200 sm:tw-px-6"
    >

      <div class="tw-hidden sm:tw-flex-1 sm:tw-flex sm:tw-items-center sm:tw-justify-between">
        <div>
          <p class="tw-text-sm tw-leading-5 tw-text-gray-700">
            Showing
            <span class="tw-font-medium">{{ pagination.from }}</span>
            to
            <span class="tw-font-medium">{{ pagination.to }}</span>
            of
            <span class="tw-font-medium">{{ pagination.total }}</span>
            results
          </p>
        </div>
      </div>

      <div class="tw-flex-1 tw-flex tw-justify-end">
        <a
          href="javascript:void(0)"
          class="tw-relative tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-text-sm tw-leading-5 tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-text-gray-500 focus:tw-outline-none focus:tw-shadow-outline-blue focus:tw-border-blue-300 active:tw-bg-gray-100 active:tw-text-gray-700 tw-transition tw-ease-in-out tw-duration-150"
          :class="{'tw-cursor-not-allowed': isFirstPage}"
          @click="prev"
        >
          Previous
        </a>
        <a
          href="javascript:void(0)"
          class="tw-ml-3 tw-relative tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-text-sm tw-leading-5 tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-text-gray-500 focus:tw-outline-none focus:tw-shadow-outline-blue focus:tw-border-blue-300 active:tw-bg-gray-100 active:tw-text-gray-700 tw-transition tw-ease-in-out tw-duration-150"
          :class="{'tw-cursor-not-allowed': isLastPage}"
          @click="next"
        >
          Next
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'

type Pagination = {
  from: number;
  to: number;
  total: number;
  currentPage: number;
  lastPage: number;
}

export default defineComponent({
  name: 'Pagination',
  props: {
    pagination: {
      required: true,
      type: Object as PropType<Pagination>
    }
  },
  emits: ['change'],
  setup(props, { emit }) {
    const next = () => {
      if (props.pagination.currentPage === props.pagination.lastPage) {
        return
      }
      emit('change', props.pagination.currentPage + 1)
    }

    const prev = () => {
      if (props.pagination.currentPage === 1) {
        return
      }
      emit('change', props.pagination.currentPage - 1)
    }

    const isFirstPage = computed(() => {
      return props.pagination.currentPage === 1
    })
    const isLastPage = computed(() => {
      return props.pagination.currentPage === props.pagination.lastPage
    })

    return {
      next,
      prev,
      isFirstPage,
      isLastPage
    }
  }
})
</script>
