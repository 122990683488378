
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import useImageOperation from '@/hooks/useImageOperations'
import ImageOperationScale from '@/components/image-operation/ImageOperationScale.vue'
import ImageOperationRotate from '@/components/image-operation/ImageOperationRotate.vue'
import ImageOperationMove from '@/components/image-operation/ImageOperationMove.vue'
import ImageOperationFlip from '@/components/image-operation/ImageOperationFlip.vue'
import ImageOperationFit from '@/components/image-operation/ImageOperationFit.vue'

type PrintQuality = 'Bad' | 'Medium' | 'Good' | 'N/A'

export default defineComponent({
  name: 'EditorPropertiesPanelImage',
  components: {
    ImageOperationScale,
    ImageOperationRotate,
    ImageOperationMove,
    ImageOperationFlip,
    ImageOperationFit
  },
  setup() {
    const store = useStore()
    const printQuality = computed(() => {
      return store.state.designer.currentObjectPrintQuality
    })

    const printQualityClass = computed(() => {
      return {
        Bad: 'print_quality--bad',
        Medium: 'print_quality--medium',
        Good: 'print_quality--good'
      }[printQuality.value.quality]
    })
    return {
      printQuality,
      printQualityClass,
      ...useImageOperation()
    }
  }
})
