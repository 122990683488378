import { MutationTree } from 'vuex'
import { ModuleState } from './moduleDesign'

export default {
  SET_INITIALIZED (state, initalized) {
    state.initialized = initalized
  },
  SET_CURRENT_OBJECT (state, obj) {
    state.currentObject = obj
  },
  SET_CURRENT_OBJECT_PRINT_QUALITY (state, quality) {
    state.currentObjectPrintQuality = quality
  },
  SET_CURRENT_COLOR (state, color) {
    state.currentColor = color
  },
  SET_OBJECTS (state, objects) {
    if (state.objects.length) {
      state.objects.splice(0, state.objects.length)
    }
    for (const obj of objects) {
      state.objects.push(obj)
    }
  },
  SET_DESIGN_MODEL (state, model) {
    state.designModel = model
  },
  SET_CUSTOMIZER_MODE (state, mode) {
    state.mode = mode
  },
  SET_MOCKUPS (state, mockups) {
    state.mockups.splice(0, state.mockups.length, ...mockups)
  },
  SET_CUSTOMIZER_VISIBLE (state, visible) {
    state.customizerVisible = visible
  }
} as MutationTree<ModuleState>
