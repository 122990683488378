import { IEventManager } from './EventManager'
import { IImageObject } from './Objects/ImageObject'

export interface BluePrintConfigData {
  guideLineViewWidth: number;
  cropping: {
    width: number;
    height: number;
    left: number;
    top: number;
  };
  psd: {
    dpi: number;
    width: number;
  };
}

export interface BluePrintData {
  id: number;
  name: string;
  guideLine: string;
  config: BluePrintConfigData;
}

export interface ImageData {
  id: number;
  name: string;
  url: string;
  config: {
    width: number;
    height: number;
    multiply: {
      key: string;
    };
    softLight: {
      key: string;
    };
    overlay: {
      key: string;
    };
  };
  stickingAreas: {
    id: number;
    bluePrintId: number;
    config: {
      distortion: {
        mapping: {
          key: string;
        };
        target: {
          width: number;
          height: number;
        };
      };
      sticking: {
        width: number;
        height: number;
        left: number;
        top: number;
      };
    };
  }[];
}

export interface DesignModelData {
  id: number;
  code: string;
  name: string;
  bluePrints: BluePrintData[];
  images: ImageData[];
  resource: {
    templateUrl: string;
    templatePreviewUrl: string;
  };
}

export interface Side {
  id: number;
  name: string;
  config: BluePrintConfigData;
  guideLine: string;
  canvasWidth: number;
  canvasHeight: number;
  backgroundColor: string;
  objects: IImageObject[];
  active: boolean;
}

export interface ISidesManager {
  eventManager: IEventManager;
  sides: Side[];
  getCurrent (): Side;
  all (): Side[];
  active (id: number): void;
  setSides (sides: Side[]): void;
  update (id: number, side: Side): void;
  find (id: number): Side;
  isEmpty(): boolean;
}

export default class SidesManager implements ISidesManager {
  eventManager: IEventManager;

  sides = []

  /**
     * @param {EventManager} eventManager
     */
  constructor (eventManager) {
    this.eventManager = eventManager
  }

  isEmpty (): boolean {
    return this.sides.length === 0
  }

  getCurrent (): Side {
    return this.sides.find(side => side.active)
  }

  /**
     * 返回所有 sides
     * @returns {array}
     */
  all (): Side[] {
    return this.sides
  }

  active (id: number): void {
    const target = this.find(id)
    for (const side of this.sides) {
      side.active = false
    }
    target.active = true
  }

  setSides (sides: Side[]): void {
    this.sides = sides.map((side, idx) => {
      side.active = !idx
      return side
    })

    // this.eventManager.trigger('Sides.OnSetSides', this.sides)
  }

  update (id: number, side: Side): void {
    const target = this.find(id)
    this.sides.splice(this.getIndex(target), 1, side)
  }

  find (id: number): Side {
    return this.sides.find(side => side.id === id)
  }

  protected getIndex (side: Side): number {
    return this.sides.indexOf(side)
  }
}
