import { computed } from 'vue'
import { useStore } from 'vuex'

export default () => {
  const store = useStore()

  const designModel = computed(() => {
    return store.state.designer.designModel
  })

  const resolution = `${designModel.value.bluePrints[0].config.psd.width} x ${designModel.value.bluePrints[0].config.psd.width}`
  const dpi = designModel.value.bluePrints[0].config.psd.dpi
  const templatePreviewUrl = designModel.value.resource.templatePreviewUrl
  const templateUrl = designModel.value.resource

  return {
    resolution,
    dpi,
    templatePreviewUrl,
    templateUrl
  }
}
