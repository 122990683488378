import { IDesigner } from '@/Designer'
import { storeCurrentSide, buildBluePrintParams } from './commonFuncs'
import { cloneDeep } from 'lodash-es'
import { groupMockups } from '@/utils/helpers'
import { Mockup } from '../moduleDesign'

function getImagesData(side) {
  return side.objects
    .map((image, idx) => ({
      id: image.meta.id,
      url: image.meta.sourceUrl,
      key: image.meta.sourceKey,
      placeholder: `{{images.${idx}}}`
    }))
}

export default (designer: IDesigner, mockups: Mockup[], degree?: number) => {
  storeCurrentSide(designer, true)

  const designs = designer.sidesManager.all().map(side => {
    const bluePrintParams = buildBluePrintParams(side)
    return {
      bluePrintId: side.id,
      name: side.name,
      canvas: {
        svg: bluePrintParams.canvas.svg,
        multiplier: bluePrintParams.canvas.multiplier
      },
      images: getImagesData(side)
    }
  })

  const mockupGroups = groupMockups(mockups)
  const keys = Object.keys(mockupGroups)

  return Promise.resolve({
    design: {
      textureDegree: degree || 2,
      bluePrints: designs
    },
    mockups: cloneDeep(keys.length === 1 ? mockupGroups[keys[0]] : mockupGroups[degree])
  })
}
