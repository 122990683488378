import { createStore } from 'vuex'
import moduleDesign from './moduleDesign'
import moduleStatus from './moduleStatus'
import moduleImage from './moduleImage'
import moduleConfig from './moduleConfig'

export type StoreState = {}

export default createStore<StoreState>({
  state: {},
  mutations: {
  },
  actions: {
  },
  modules: {
    designer: moduleDesign,
    status: moduleStatus,
    image: moduleImage,
    config: moduleConfig
  }
})
