
import { defineComponent, PropType, computed } from 'vue'

type Pagination = {
  from: number;
  to: number;
  total: number;
  currentPage: number;
  lastPage: number;
}

export default defineComponent({
  name: 'Pagination',
  props: {
    pagination: {
      required: true,
      type: Object as PropType<Pagination>
    }
  },
  emits: ['change'],
  setup(props, { emit }) {
    const next = () => {
      if (props.pagination.currentPage === props.pagination.lastPage) {
        return
      }
      emit('change', props.pagination.currentPage + 1)
    }

    const prev = () => {
      if (props.pagination.currentPage === 1) {
        return
      }
      emit('change', props.pagination.currentPage - 1)
    }

    const isFirstPage = computed(() => {
      return props.pagination.currentPage === 1
    })
    const isLastPage = computed(() => {
      return props.pagination.currentPage === props.pagination.lastPage
    })

    return {
      next,
      prev,
      isFirstPage,
      isLastPage
    }
  }
})
