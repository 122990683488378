import { Module } from 'vuex'
import { StoreState } from '.'
import moduleConfigActions from './moduleConfigActions'
import moduleConfigMutations from './moduleConfigMutations'

export type ModuleState = {
  bucket: {
    region: string;
  };
  uuid: string;
  isSaveDraf: boolean;
}

export default {
  namespaced: true,
  state: {
    uuid: '',
    isSaveDraf: false,
    bucket: {
      region: 'cn'
    }
  },
  actions: moduleConfigActions,
  mutations: moduleConfigMutations
} as Module<ModuleState, StoreState>
