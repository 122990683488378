<template>
    <div
      class="material-panels tw-relative tw-flex"
      style="width:425px;"
      :style="{'width': collapse ? '75px': '425px'}"
    >
      <material-panel-tabs
        style="width: 75px"
        :current-component="currentComponent"
        @tab-clicked="tweakTab"
      ></material-panel-tabs>

      <material-panel-content
        :current-component="currentComponent"
        style="width: 350px"
        :style="{ display: collapse ? 'none' : 'block' }"
        @edit-color="tweakTab('MaterialPanelContentBackground')"
        @image-clicked="handleImageClick"
      ></material-panel-content>

      <div
        class="material-panel-collapse-btn tw-bg-gray-700 tw-absolute tw-flex tw-items-center tw-justify-center tw-cursor-pointer hover:tw-text-gray-300"
        style="width: 15px; height: 80px; top: 50%; left: 100%"
        @click="toggleCollapse"
      >
        <button>
          <svg v-if="collapse" t="1602609279496" class="tw-w-full tw-h-auto tw-fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6453"><path d="M593.450667 512.128L360.064 278.613333l45.290667-45.226666 278.613333 278.762666L405.333333 790.613333l-45.226666-45.269333z" p-id="6454"></path></svg>
          <svg v-else t="1602609386105" class="tw-w-full tw-h-auto tw-fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6626"><path d="M641.28 278.613333l-45.226667-45.226666-278.634666 278.762666 278.613333 278.485334 45.248-45.269334-233.365333-233.237333z" p-id="6627"></path></svg>
        </button>
      </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import MaterialPanelTabs from '@/components/MaterialPanelTabs.vue'
import MaterialPanelContent, { AvailableComponents } from '@/components/MaterialPanelContent.vue'

export default defineComponent({
  name: 'MaterialPanel',
  components: {
    MaterialPanelTabs,
    MaterialPanelContent
  },
  mounted() {
    this.collapse = this.isPhone
  },
  setup () {
    const collapse = ref(false)
    const toggleCollapse = () => {
      collapse.value = !collapse.value
    }

    const currentComponent = ref('MaterialPanelContentMyFiles')
    const tweakTab = (component: AvailableComponents) => {
      const toggleBtn = document.querySelector('.material-panel-collapse-btn') as HTMLElement
      // 点击当前显示的标签，则隐藏
      if (currentComponent.value === component) {
        toggleBtn.click()
      } else {
        // 切换标签，但是内容被折叠，显示
        if (collapse.value) {
          toggleBtn.click()
        }
        currentComponent.value = component
      }
    }

    return {
      collapse,
      toggleCollapse,
      currentComponent,
      tweakTab
    }
  },
  methods: {
    handleImageClick() {
      if (this.isPhone) {
        this.collapse = true
      }
    }
  }
})
</script>

<style scoped>

</style>
