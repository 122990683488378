import { Module } from 'vuex'
import { StoreState } from '.'
import moduleDesignActions from './moduleDesignActions'
import moduleDesignMutations from './moduleDesignMutations'
import ImageObject, { IImageObject } from '@/Designer/Features/Objects/ImageObject'
import { DesignModelData } from '@/Designer/Features/SidesManager'

export type Mockup = {
  url: string;
  id: number;
  textureDegree: number;
  progress: number;
}

export enum CustomizerMode {
  MERCHAT = 'MERCHANT',
  CUSTOMER = 'CUSTOMER'
}

export interface ModuleState {
  mode: CustomizerMode;
  initialized: boolean;
  currentObject: null | IImageObject;
  currentObjectPrintQuality: {
    psd: number;
    dpi: number;
    quality: string;
  };
  currentColor: string;
  objects: IImageObject[];
  designModel: DesignModelData;
  mockups: Mockup[];
  customizerVisible: boolean;
}

export default {
  namespaced: true,
  state: () => ({
    mode: CustomizerMode.MERCHAT,
    initialized: false,
    currentObject: null,
    currentObjectPrintQuality: {
      psd: 0,
      dpi: 0,
      quality: 'N/A'
    },
    objects: [],
    designModel: null,
    currentColor: '',
    mockups: [],
    customizerVisible: false
  }),
  getters: {
    currentObjectType (state) {
      if (state.currentObject instanceof ImageObject) {
        return 'Image'
      }
      return ''
    }
  },
  mutations: moduleDesignMutations,
  actions: moduleDesignActions
} as Module<ModuleState, StoreState>
