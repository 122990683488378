
import { defineComponent } from 'vue'
import PreviewGenerationProgress from '@/components/PreviewGenerationProgress.vue'
import useGenerationProgress from '@/hooks/useGenerationProgress'

export default defineComponent({
  name: 'EditorView',
  components: {
    PreviewGenerationProgress
  },
  setup () {
    return {
      ...useGenerationProgress()
    }
  }
})
