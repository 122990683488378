import { computed } from 'vue'
import { useStore } from 'vuex'

export default function () {
  const store = useStore()

  const image = computed(() => {
    return store.state.designer.currentObject
  })

  const removeImage = () => {
    store.dispatch('designer/removeCurrentImage')
  }

  const zoom = (ratio) => {
    image.value.zoom(ratio)
    store.dispatch('designer/render')
  }

  const rotate = (angle) => {
    image.value.rotate(angle)
    store.dispatch('designer/render')
  }

  const center = () => {
    image.value.center()
    store.dispatch('designer/render')
  }

  const fill = () => {
    // TODO: 需要两次
    image.value.fill()
    image.value.fill()
    store.dispatch('designer/render')
  }

  const fit = (direction: 'X' | 'Y') => {
    // TODO: 需要两次
    image.value.fit(direction)
    image.value.fit(direction)
    store.dispatch('designer/render')
  }

  const duplicate = () => {
    store.dispatch('designer/duplicateCurrentImage')
  }

  const move = ([x, y]) => {
    image.value.move(x, y)
    store.dispatch('designer/render')
  }

  const flip = (direction) => {
    image.value.flip(direction)
    store.dispatch('designer/render')
  }

  return {
    removeImage,
    zoom,
    center,
    rotate,
    move,
    fill,
    fit,
    duplicate,
    flip
  }
}
