<template>
  <div id="editor-container">
    <mobile-editor-view></mobile-editor-view>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MobileEditorView from '@/components/mobile/MobileEditorView.vue'

export default defineComponent({
  name: 'MobileEditor',
  components: {
    MobileEditorView
  }
})
</script>
