
import { defineComponent, ref } from 'vue'
import MaterialPanelTabs from '@/components/MaterialPanelTabs.vue'
import MaterialPanelContent, { AvailableComponents } from '@/components/MaterialPanelContent.vue'

export default defineComponent({
  name: 'MaterialPanel',
  components: {
    MaterialPanelTabs,
    MaterialPanelContent
  },
  mounted() {
    this.collapse = this.isPhone
  },
  setup () {
    const collapse = ref(false)
    const toggleCollapse = () => {
      collapse.value = !collapse.value
    }

    const currentComponent = ref('MaterialPanelContentMyFiles')
    const tweakTab = (component: AvailableComponents) => {
      const toggleBtn = document.querySelector('.material-panel-collapse-btn') as HTMLElement
      // 点击当前显示的标签，则隐藏
      if (currentComponent.value === component) {
        toggleBtn.click()
      } else {
        // 切换标签，但是内容被折叠，显示
        if (collapse.value) {
          toggleBtn.click()
        }
        currentComponent.value = component
      }
    }

    return {
      collapse,
      toggleCollapse,
      currentComponent,
      tweakTab
    }
  },
  methods: {
    handleImageClick() {
      if (this.isPhone) {
        this.collapse = true
      }
    }
  }
})
