import { cloneDeep } from 'lodash-es'
import { fabric } from 'fabric'
import { IDesigner } from '@/Designer'
import { Side } from '@/Designer/Features/SidesManager'

export type BluePrintParams = {
  id: number;
  canvas: {
    svg: string;
    cWidth: number;
    multiplier: number;
  };
  config: {
    cropping: {
      width: number;
      height: number;
      left: number;
      top: number;
    };
  };
  images: string[];
}

export function storeCurrentSide (designer: IDesigner, isActive: boolean): void {
  const currentSide = designer.sidesManager.getCurrent()

  const data = Object.assign({}, currentSide, {
    objects: cloneDeep(designer.objectsManager.all()),
    active: isActive
  })

  designer.sidesManager.update(currentSide.id, data)
}

export function buildBluePrintParams (side: Side): BluePrintParams {
  const staticCanvas = new fabric.StaticCanvas('static-canvas')
  staticCanvas.setWidth(side.canvasWidth)
  staticCanvas.setHeight(side.canvasHeight)
  side.objects.forEach(obj => staticCanvas.add(cloneDeep(obj.obj)))
  staticCanvas.backgroundColor = side.backgroundColor
  let svg = staticCanvas.toSVG()
  staticCanvas.dispose()

  const images = []
  side.objects
    .forEach((image, idx) => {
      // 替换 svg 中图片的 url 为占位符
      svg = svg.replace(image.meta.thumbUrl, `{{images:${idx}}}`)
      images.push(image.meta.sourceKey)
    })

  return {
    id: side.id,
    images,
    canvas: {
      svg,
      cWidth: side.canvasWidth,
      multiplier: side.config.guideLineViewWidth / side.canvasWidth // 用于还原为操作图实际的大小
    },
    config: {
      cropping: side.config.cropping
    }
  }
}
