
import { defineComponent, ref, watchEffect, nextTick } from 'vue'
import Swiper, { Navigation, Pagination, SwiperOptions } from 'swiper'
import 'swiper/swiper-bundle.css'
import PreviewGenerationProgress from '@/components/PreviewGenerationProgress.vue'
import useGenerationProgress from '@/hooks/useGenerationProgress'
import useEscListener from '@/hooks/useEscListener'

Swiper.use([Navigation, Pagination])

export default defineComponent({
  name: 'MobilePreview',
  components: {
    PreviewGenerationProgress
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { close } = useEscListener(emit)

    const { images, isDesignEdited, progress, isGenerating } = useGenerationProgress()

    const swiper = ref<Swiper | null>(null)

    watchEffect(() => {
      if (!images.value.length) {
        return
      }
      nextTick(() => {
        if (swiper.value) {
          swiper.value.update()
        } else {
          nextTick(() => {
            // eslint-disable-next-line no-new
            swiper.value = new Swiper('.mobile-preview-swiper-container', {
              loop: true,
              pagination: {
                el: '.mobile-preview-swiper-pagination',
                // type: 'fraction',
                clickable: true
              },
              navigation: {
                nextEl: '.mobile-preview-swiper-button-next',
                prevEl: '.mobile-preview-swiper-button-prev'
              }
            } as SwiperOptions)
          })
        }
      })
    })
    return {
      swiper,
      images,
      isDesignEdited,
      progress,
      isGenerating,
      close
    }
  }
})
