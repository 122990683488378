import { useStore } from 'vuex'
import { computed } from 'vue'
import { Mockup } from '@/store/moduleDesign'
import { groupMockups } from '@/utils/helpers'

export default () => {
  const store = useStore()

  const images = computed<Mockup[]>(() => {
    return store.state.designer.mockups
  })

  const progress = computed(() => {
    return store.state.status.generation.progress
  })

  const isGenerating = computed(() => {
    return store.state.status.generation.progress > 0
  })

  const generateMockups = async (degrees?: number) => {
    try {
      await store.dispatch('designer/generateMockups', degrees)
    } catch (e) {
      alert(e)
      throw e
    }
  }

  const isDesignEdited = computed(() => {
    return store.state.status.isDesignEdited
  })

  const degrees = computed(() => {
    return [...new Set(images.value.map(i => i.textureDegree))]
  })

  const imageGroups = computed<{ [key: string]: Mockup[] }>(() => {
    return groupMockups(images.value)
  })

  const firstGroup = computed<Mockup[]>(() => {
    const keys = Object.keys(imageGroups.value)
    if (!keys.length) {
      return []
    }
    return imageGroups.value[keys[0]]
  })

  return {
    images,
    degrees,
    imageGroups,
    firstGroup,
    isDesignEdited,
    progress,
    isGenerating,
    generateMockups
  }
}
