import { MutationTree } from 'vuex'
import { ImageData } from '@/store/funcs/addImage'
import { moduleImageState, Pagination } from './moduleImage'

export default {
  SET_PAGE(state, page) {
    state.pagination.currentPage = page
  },
  SET_LIST(state, list) {
    state.list.splice(0, state.list.length, ...list)
  },
  SET_PAGINATION(state, pagination: Pagination) {
    state.pagination.currentPage = pagination.currentPage
    state.pagination.from = pagination.from
    state.pagination.to = pagination.to
    state.pagination.total = pagination.total
    state.pagination.perPage = pagination.perPage
    state.pagination.lastPage = pagination.lastPage
  },
  DELETE_IMAGE(state, image: ImageData) {
    state.list.splice(state.list.indexOf(image), 1)
  },
  CREATE_IMAGE(state, image: ImageData) {
    state.list.unshift(image)
  }
} as MutationTree<moduleImageState>
