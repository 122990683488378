<template>
  <div>
    <button class="tw-border-1 tw-border-gray-500 tw-mr-4" @click="move([0, -5])">
      <svg
        t="1602557588035"
        class="fill-current"
        :class="size === 'sm' ? 'tw-w-5 tw-h-5' : 'tw-w-10 tw-h-10'"
        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8817"
      ><path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z" p-id="8818"></path></svg>
    </button>
    <button class="tw-border-1 tw-border-gray-500 tw-mr-4" @click="move([0, 5])">
      <svg
        t="1602557603965"
        class="fill-current"
        :class="size === 'sm' ? 'tw-w-5 tw-h-5' : 'tw-w-10 tw-h-10'"
        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8990"
      ><path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z" p-id="8991"></path></svg>
    </button>
    <button class="tw-border-1 tw-border-gray-500 tw-mr-4" @click="move([-5, 0])">
      <svg
        t="1602557615718"
        class="fill-current"
        :class="size === 'sm' ? 'tw-w-5 tw-h-5' : 'tw-w-10 tw-h-10'"
        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9163"
      ><path d="M689 165.1L308.2 493.5c-10.9 9.4-10.9 27.5 0 37L689 858.9c14.2 12.2 35 1.2 35-18.5V183.6c0-19.7-20.8-30.7-35-18.5z" p-id="9164"></path></svg>
    </button>
    <button class="tw-border-1 tw-border-gray-500" @click="move([5, 0])">
      <svg
        t="1602557631141"
        class="fill-current"
        :class="size === 'sm' ? 'tw-w-5 tw-h-5' : 'tw-w-10 tw-h-10'"
        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9336"
      ><path d="M715.8 493.5L335 165.1c-14.2-12.2-35-1.2-35 18.5v656.8c0 19.7 20.8 30.7 35 18.5l380.8-328.4c10.9-9.4 10.9-27.6 0-37z" p-id="9337"></path></svg>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import useImageOperation from '@/hooks/useImageOperations'

export default defineComponent({
  name: 'ImageOperationMove',
  props: {
    size: {
      type: String,
      rquired: false,
      default: 'sm'
    }
  },
  setup () {
    return {
      ...useImageOperation()
    }
  }
})
</script>
