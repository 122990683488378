import { MutationTree } from 'vuex'
import { ModuleState } from './moduleStatus'

export default {
  SET_GENERATION_STATUS (state, progress) {
    state.generation.progress = progress
  },
  SET_GENERATION_TIMER (state, timer) {
    state.generation.timer = timer
  },
  CLEAR_GENERATION_TIMER (state) {
    window.clearInterval(state.generation.timer)
    state.generation.timer = 0
  },
  SET_GENERATION_PROGRESS (state, progress) {
    state.generation.progress = progress
  },
  SET_GENERATION_HANDLER(state, handler) {
    state.generation.handler = handler
  },
  APPEND_DOWNLOAD_ITEM (state, downloadItem) {
    const target = state.download.pool.find(i => i.id === downloadItem.id)
    if (target) {
      state.download.pool.splice(state.download.pool.indexOf(target), 1, downloadItem)
    } else {
      state.download.pool.push(downloadItem)
    }
  },
  UPDATE_DOWNLOAD_ITEM_PROGRESS (state, downloadItem) {
    const target = state.download.pool.find(i => i.id === downloadItem.id)
    if (target) {
      state.download.pool.splice(state.download.pool.indexOf(target), 1, downloadItem)
    }
  },
  REMOVE_DOWNLOAD_ITEM (state, downloadItem) {
    const target = state.download.pool.find(i => i.id === downloadItem.id)
    if (target) {
      state.download.pool.splice(state.download.pool.indexOf(target), 1)
    }
  },
  RECORD_DESIGN_EDITED(state) {
    state.isDesignEdited = true
  },
  CLEAR_DESIGN_EDITED(state) {
    state.isDesignEdited = false
  },
  SET_UPLOADER_PROGRESS(state, progress) {
    state.uploader.progress = progress
  },
  SET_UPLOADER_UPLOADING(state, uploading) {
    state.uploader.uploading = uploading
  },
  SET_SAVE_DRAF_STATUS (state, status) {
    state.saveDraf.status = status
  }
} as MutationTree<ModuleState>
