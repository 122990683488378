
import DesignSpace from '@/components/DesignSpace.vue'
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import MobileDesignSpace from '@/components/mobile/MobileDesignSpace.vue'

export default defineComponent({
  name: 'App',
  components: {
    DesignSpace,
    MobileDesignSpace
  },
  setup () {
    const store = useStore()
    const customizerVisible = computed(() => {
      return store.state.designer.customizerVisible
    })
    return {
      customizerVisible
    }
  }
})
