import { randomStr } from '@/utils/helpers'
import { ActionTree } from 'vuex'
import { StoreState } from '.'
import { ModuleState } from './moduleConfig'

export default {
  config(ctx, data) {
    const region = data.region ? data.region : 'cn'
    ctx.commit('SET_BUCKET_REGION', region)
    ctx.commit('SET_IS_SAVE_DRAF', !!data.isSaveDraf)
  },
  setNewUUID(ctx) {
    ctx.commit('SET_UUID', randomStr())
  }
} as ActionTree<ModuleState, StoreState>
