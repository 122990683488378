<template>
  <div class="editor-view tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full">
    <div id="design-area__container">
      <canvas id="design-canvas"></canvas>
    </div>

    <!-- used to handle blueprint -->
    <canvas class="tw-hidden" id="static-canvas"></canvas>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MobileEditorView'
})
</script>
