<template>
  <div class="design-space-container tw-w-screen tw-h-screen">
    <mobile-toolbar></mobile-toolbar>
    <mobile-editor></mobile-editor>
    <mobile-material-panel></mobile-material-panel>
    <mobile-editor-properties-panel></mobile-editor-properties-panel>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MobileToolbar from '@/components/mobile/MobileToolbar.vue'
import MobileEditor from '@/components/mobile/MobileEditor.vue'
import MobileMaterialPanel from '@/components/mobile/MobileMaterialPanel.vue'
import MobileEditorPropertiesPanel from '@/components/mobile/MobileEditorPropertiesPanel.vue'

export default defineComponent({
  name: 'MobileDesignSpace',
  components: {
    MobileToolbar,
    MobileEditor,
    MobileMaterialPanel,
    MobileEditorPropertiesPanel
  }
})
</script>
