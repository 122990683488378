
import { defineComponent, PropType, computed } from 'vue'
import { ImageData } from '@/store/funcs/addImage'

type ProgressImage = {
  id: number;
  progress: number;
}

export default defineComponent({
  name: 'ImageProgress',
  props: {
    title: {
      required: false,
      type: String,
      default: 'Download'
    },
    image: {
      required: true,
      type: Object as PropType<ImageData>
    },
    list: {
      required: true,
      type: Array as PropType<ProgressImage[]>
    },
    showProgressBar: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const progress = computed(() => {
      const target = props.list.find(item => item.id === props.image.id)
      if (target) {
        return target.progress
      }
      return 0
    })

    return {
      progress
    }
  }
})
