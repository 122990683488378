
import { defineComponent, ref } from 'vue'
import useImageOperation from '@/hooks/useImageOperations'
import ImageOperationScale from '@/components/image-operation/ImageOperationScale.vue'
import ImageOperationRotate from '@/components/image-operation/ImageOperationRotate.vue'
import ImageOperationMove from '@/components/image-operation/ImageOperationMove.vue'
import ImageOperationFlip from '@/components/image-operation/ImageOperationFlip.vue'
import ImageOperationFit from '@/components/image-operation/ImageOperationFit.vue'

export default defineComponent({
  name: 'MobileEditorPropertiesPanelImage',
  components: {
    ImageOperationScale,
    ImageOperationRotate,
    ImageOperationMove,
    ImageOperationFlip,
    ImageOperationFit
  },
  setup () {
    const display = ref(false)
    const secType = ref('')
    const materialBtn = document.querySelector('.material-panel__button') as HTMLElement
    const displaySecBtns = (type: string) => {
      secType.value = type
      display.value = true
      materialBtn.style.display = 'none'
    }
    const cancel = () => {
      display.value = false
      materialBtn.style.display = ''
    }
    const done = () => {
      display.value = false
      materialBtn.style.display = ''
    }
    return {
      displaySecBtns,
      secType,
      cancel,
      done,
      display,
      ...useImageOperation()
    }
  }
})
