<template>
  <div class="preview-container tw-bg-white">
    <div class="preview-head tw-flex tw-justify-between tw-items-center tw-bg-gray-300" style="width:220px; height: 30px">
      <button class="tw-h-full tw-pl-2 focus:tw-outline-none" @click="toggleMinimize">
        <svg v-show="!isMinimize" t="1602557084542" class="tw-cursor-pointer" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8260"  width="16" height="16"><path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z" p-id="8261"></path></svg>

        <svg v-show="isMinimize" t="1602556995609" class="tw-cursor-pointer" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8048" width="16" height="16" ><path d="M474 152m8 0l60 0q8 0 8 8l0 704q0 8-8 8l-60 0q-8 0-8-8l0-704q0-8 8-8Z" p-id="8049"></path><path d="M168 474m8 0l672 0q8 0 8 8l0 60q0 8-8 8l-672 0q-8 0-8-8l0-60q0-8 8-8Z" p-id="8050"></path></svg>
      </button>
      <p class="">Preview</p>
      <button class="tw-h-full tw-pr-2 focus:tw-outline-none" @click="fullscreen">
        <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/></svg>
      </button>
    </div>

    <div v-show="!isMinimize" class="preview-swiper swiper-container tw-relative">
        <button v-show="!isGenerating" class="tw-btn-flat tw-btn-xs tw-btn-blue tw-absolute tw-left-0 tw-top-0 tw-ml-1 tw-mt-1 tw-z-10" @click="generateMockups()">Update Mockups</button>

        <preview-generation-progress class="tw-flex tw-justify-center tw-w-full tw-z-10 tw-absolute" v-show="isGenerating"></preview-generation-progress>

        <div class="swiper-wrapper">
            <div
              class="swiper-slide tw-bg-center tw-bg-no-repeat tw-bg-contain tw-cursor-zoom-in"
              v-for="image in firstGroup"
              :key="image.url"
              :style="'background-image:url('+$process(image.url, { width: 200 })+')'"
              @click="isFullscreen = true"
            >
              <image-progress class="tw-mt-20" :image="image" :list="firstGroup" title="Generating" :show-progress-bar="false"></image-progress>
            </div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
    </div>

    <preview-fullscreen v-if="isFullscreen" @close="isFullscreen = false"></preview-fullscreen>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect, nextTick } from 'vue'
import Swiper, { Navigation, Pagination, SwiperOptions } from 'swiper'
import 'swiper/swiper-bundle.css'
import PreviewFullscreen from '@/components/PreviewFullscreen.vue'
import PreviewGenerationProgress from '@/components/PreviewGenerationProgress.vue'
import ImageProgress from '@/components/ImageProgress.vue'
import useGenerationProgress from '@/hooks/useGenerationProgress'

Swiper.use([Navigation, Pagination])

export default defineComponent({
  name: 'Preview',
  components: {
    PreviewFullscreen,
    PreviewGenerationProgress,
    ImageProgress
  },
  setup () {
    const { images, firstGroup, isDesignEdited, progress, isGenerating, generateMockups } = useGenerationProgress()

    const swiper = ref<Swiper | null>(null)

    watchEffect(() => {
      if (!images.value.length) {
        return
      }
      if (swiper.value) {
        nextTick(() => {
          window.setTimeout(() => {
            swiper.value.update()
          }, 300)
        })
      } else {
        nextTick(() => {
          swiper.value = new Swiper('.swiper-container', {
            loop: true,
            pagination: {
              el: '.swiper-pagination',
              // type: 'fraction',
              clickable: true
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },
            allowTouchMove: false
          } as SwiperOptions)
          window._test = swiper.value
        })
      }
    })

    const isFullscreen = ref(false)
    const fullscreen = () => {
      isFullscreen.value = true
    }

    const isMinimize = ref(false)
    const toggleMinimize = () => {
      isMinimize.value = !isMinimize.value
    }

    return {
      firstGroup,
      isDesignEdited,
      progress,
      isGenerating,
      generateMockups,
      isFullscreen,
      swiper,
      fullscreen,
      isMinimize,
      toggleMinimize
    }
  }
})
</script>

<style lang="postcss" scoped>
.preview-container {
  box-shadow: 0 1px 8px 0 rgba(0,0,0,.5);
}

::v-global(.swiper-container) {
  width: 220px;
  height:220px;
}

::v-global(:root) {
  --swiper-navigation-size: 26px;
}

::v-global(.swiper-button-prev),
::v-global(.swiper-button-next) {
  width: calc(var(--swiper-navigation-size) / 44 * 27) !important;
  height: var(--swiper-navigation-size) !important;
  margin-top: calc(-1 * var(--swiper-navigation-size) / 2) !important;
  &:after {
    font-size: var(--swiper-navigation-size) !important;
  }
}
</style>
