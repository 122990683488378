<template>
  <div class="editor-view tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full" style="margin: 24px">
    <div v-show="isDesignEdited || isGenerating" class="tw-absolute tw-w-full tw-flex tw-justify-center tw-items-center tw-px-4 tw-border-r-1 tw-border-gray-200" style="top: 50px">
      <p class="tw-text-red-600">
        <span v-show="isDesignEdited">Design edited. <a class="tw-text-blue-500" href="javascript:void(0);" @click="generateMockups()">Click here</a> to update mockups.</span>
        <preview-generation-progress v-show="isGenerating" :show-title="!isDesignEdited"></preview-generation-progress>
      </p>
    </div>

    <div id="design-area__container">
      <canvas id="design-canvas"></canvas>
    </div>

    <!-- used to handle blueprint -->
    <canvas id="static-canvas" hidden></canvas>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import PreviewGenerationProgress from '@/components/PreviewGenerationProgress.vue'
import useGenerationProgress from '@/hooks/useGenerationProgress'

export default defineComponent({
  name: 'EditorView',
  components: {
    PreviewGenerationProgress
  },
  setup () {
    return {
      ...useGenerationProgress()
    }
  }
})
</script>
