
import { defineComponent, PropType } from 'vue'
import MaterialPanelContentBackground from '@/components/MaterialPanelContentBackground.vue'
import MaterialPanelContentText from '@/components/MaterialPanelContentText.vue'
import MaterialPanelContentMyFiles from '@/components/MaterialPanelContentMyFiles.vue'
import MaterialPanelContentLayers from '@/components/MaterialPanelContentLayers.vue'

export type AvailableComponents = 'MaterialPanelContentText' |
  'MaterialPanelContentBackground' |
  'MaterialPanelContentMyFiles' |
  'MaterialPanelContentLayers'

export default defineComponent({
  name: 'MaterialPanelContent',
  props: {
    currentComponent: {
      required: true,
      type: String as PropType<AvailableComponents>
    }
  },
  components: {
    MaterialPanelContentBackground,
    MaterialPanelContentText,
    MaterialPanelContentMyFiles,
    MaterialPanelContentLayers
  },
  emits: ['edit-color', 'image-clicked'],
  setup(props, { emit }) {
    const editColor = () => {
      emit('edit-color')
    }

    const imageClicked = () => {
      emit('image-clicked')
    }

    return {
      imageClicked,
      editColor
    }
  }
})
