<template>
  <div class="mobile-toolbar tw-flex tw-justify-between tw-items-center tw-w-full">
    <button class="tw-w-1/4 tw-h-10 tw-bg-gray-300" @click="clear">Clear</button>
    <button class="tw-w-1/4 tw-h-10 tw-bg-gray-300" @click="previewMockups">Preview</button>
    <button class="tw-w-1/4 tw-h-10 tw-bg-gray-300" @click="displayTemplate">Template</button>
    <button class="tw-w-1/4 tw-h-10 tw-bg-gray-300" @click="done">Done</button>

    <mobile-preview v-if="preview" @close="preview = false"></mobile-preview>

    <template-preview v-if="templateVisible" @close="templateVisible = false"></template-preview>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import MobilePreview from '@/components/mobile/MobilePreview.vue'
import TemplatePreview from '@/components/TemplatePreview.vue'
import useGenerationProgress from '@/hooks/useGenerationProgress'
import useToolbarOperations from '@/hooks/useToolbarOperations'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'MobileToolbar',
  components: {
    MobilePreview,
    TemplatePreview
  },
  setup () {
    const preview = ref(false)

    const { clear, templateVisible, displayTemplate } = useToolbarOperations()

    const { generateMockups } = useGenerationProgress()

    const previewMockups = () => {
      generateMockups(2)
      preview.value = true
    }

    const store = useStore()

    const done = () => {
      store.dispatch('designer/completeDesign')
    }

    return {
      done,
      clear,
      preview,
      previewMockups,
      generateMockups,
      templateVisible,
      displayTemplate
    }
  }
})
</script>

<style lang="postcss">
.mobile-toolbar {
  height: 40px;
}
</style>
