<template>
  <div class="image-properties-panel">
    <div
      class="editor-properties-option tw-border-b-1 tw-border-gray-300 tw-flex tw-items-center tw-justify-center"
    >
      <span>Print quality: </span>&nbsp;<span :class="printQualityClass">{{
        printQuality.quality
      }}(DPI: {{ printQuality.dpi }})</span>
    </div>
    <div
      class="editor-properties-option tw-border-b-1 tw-border-gray-300 tw-cursor-pointer hover:tw-text-white hover:tw-bg-blue-700 tw-flex tw-items-center tw-justify-center"
    >
      <button class="tw-w-full tw-h-full" @click="removeImage">
        Remove
      </button>
    </div>
    <div
      class="editor-properties-option tw-border-b-1 tw-border-gray-300 tw-cursor-pointer hover:tw-text-white hover:tw-bg-blue-700 tw-flex tw-items-center tw-justify-center"
    >
      <button class="tw-w-full tw-h-full" @click="duplicate">Duplicate</button>
    </div>
    <div
      class="editor-properties-option tw-border-b-1 tw-border-gray-300 tw-flex tw-items-center"
    >
      <p style="padding-left:10px; width: 68px;">Scale</p>
      <image-operation-scale></image-operation-scale>
    </div>
    <div
      class="editor-properties-option tw-border-b-1 tw-border-gray-300 tw-flex tw-items-center"
    >
      <p style="padding-left:10px; width: 68px;">Rotate</p>
      <image-operation-rotate></image-operation-rotate>
    </div>
    <div
      class="editor-properties-option tw-border-b-1 tw-border-gray-300 tw-flex tw-items-center"
    >
      <p style="padding-left:10px; width: 68px;">Move</p>
      <image-operation-move></image-operation-move>
    </div>
    <div
      class="editor-properties-option tw-border-b-1 tw-border-gray-300 tw-flex tw-items-center"
    >
      <p style="padding-left:10px; width: 68px;">Align</p>
      <button class="tw-border-1 tw-border-gray-500" @click="center">
        <svg
          class="tw-w-5 tw-h-5 fill-current"
          t="1602558014027"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="12475"
        >
          <path
            d="M512 384C441.173333 384 384 441.173333 384 512 384 582.826667 441.173333 640 512 640 582.826667 640 640 582.826667 640 512 640 441.173333 582.826667 384 512 384M810.666667 810.666667 640 810.666667 640 896 810.666667 896C857.6 896 896 857.6 896 810.666667L896 640 810.666667 640M810.666667 128 640 128 640 213.333333 810.666667 213.333333 810.666667 384 896 384 896 213.333333C896 166.4 857.6 128 810.666667 128M213.333333 213.333333 384 213.333333 384 128 213.333333 128C166.4 128 128 166.4 128 213.333333L128 384 213.333333 384M213.333333 640 128 640 128 810.666667C128 857.6 166.4 896 213.333333 896L384 896 384 810.666667 213.333333 810.666667 213.333333 640Z"
            p-id="12476"
          ></path>
        </svg>
      </button>
    </div>
    <div
      class="editor-properties-option tw-border-b-1 tw-border-gray-300 tw-flex tw-items-center"
    >
      <p style="padding-left:10px; width: 68px;">Flip</p>
      <image-operation-flip></image-operation-flip>
    </div>
    <div
      class="editor-properties-option tw-border-b-1 tw-border-gray-300 tw-flex tw-items-center"
    >
      <p style="padding-left:10px; width: 68px;">Fit</p>
      <image-operation-fit></image-operation-fit>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import useImageOperation from '@/hooks/useImageOperations'
import ImageOperationScale from '@/components/image-operation/ImageOperationScale.vue'
import ImageOperationRotate from '@/components/image-operation/ImageOperationRotate.vue'
import ImageOperationMove from '@/components/image-operation/ImageOperationMove.vue'
import ImageOperationFlip from '@/components/image-operation/ImageOperationFlip.vue'
import ImageOperationFit from '@/components/image-operation/ImageOperationFit.vue'

type PrintQuality = 'Bad' | 'Medium' | 'Good' | 'N/A'

export default defineComponent({
  name: 'EditorPropertiesPanelImage',
  components: {
    ImageOperationScale,
    ImageOperationRotate,
    ImageOperationMove,
    ImageOperationFlip,
    ImageOperationFit
  },
  setup() {
    const store = useStore()
    const printQuality = computed(() => {
      return store.state.designer.currentObjectPrintQuality
    })

    const printQualityClass = computed(() => {
      return {
        Bad: 'print_quality--bad',
        Medium: 'print_quality--medium',
        Good: 'print_quality--good'
      }[printQuality.value.quality]
    })
    return {
      printQuality,
      printQualityClass,
      ...useImageOperation()
    }
  }
})
</script>

<style lang="postcss">
.editor-properties-option {
  height: 48px;
}
.print_quality--good {
  color: green;
}
.print_quality--medium {
  color: orange;
}
.print_quality--bad {
  color: red;
}
</style>
