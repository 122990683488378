import { Mockup } from '@/store/moduleDesign'
import isMobile from 'ismobilejs'
export const randomStr = (len = 20): string => {
  const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  const maxPos = chars.length
  let pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd + Date.now()
}

export const getSuffix = (filename: string): string => {
  const pos = filename.lastIndexOf('.')
  let suffix = ''

  if (pos !== -1) {
    suffix = filename.substring(pos)
  }

  return suffix
}

export const generateRandomName = (filename: string, len: number): string => {
  return randomStr(len) + getSuffix(filename)
}

export const customizerLog = (...args: any[]) => {
  window._wow.customizer.debug && console.log(...args)
}

export const isPhone = () => {
  return isMobile(window.navigator).phone
}

/**
 * group mockups by texture degree
 * @param images
 */
export const groupMockups = (images: Mockup[]) => {
  const groups = {}
  const degrees = [...new Set(images.map(i => i.textureDegree))]
  for (const degree of degrees) {
    groups[degree] = images.filter(i => i.textureDegree === degree)
  }
  return groups
}
