function post(type, data) {
  window.postMessage({
    source: 'wow',
    type: type,
    data
  }, null)
}

export function sendFetchImagesMsg(page: number) {
  post('fetchImages', { page })
}

export function sendDeleteImageMsg(image) {
  post('deleteImage', { id: image.id })
}

export function sendCreateImageMsg(image) {
  post('createImage', image)
}

export function sendCompleteDesignMsg(data) {
  post('completeDesign', data)
}

export function sendSaveDrafMsg(data) {
  post('saveDraf', data)
}
