<template>
  <div>
    <button class="tw-border-1 tw-border-gray-500 tw-mr-4" @click="fit('X')">
      <svg
        t="1602558566782"
        class="fill-current"
        :class="size === 'sm' ? 'tw-w-5 tw-h-5' : 'tw-w-10 tw-h-10'"
        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1258"
      ><path d="M320 320h384v384H320V320z m64 64v256h256V384H384zM159.008 520l99.552 99.584-47.488 47.488L64 520l147.072-147.072 47.52 47.488-99.584 99.616z m712.128 0l-99.552-99.584 47.488-47.488L966.144 520 819.072 667.072l-47.488-47.488L871.136 520z" p-id="1259"></path></svg>
    </button>
    <button class="tw-border-1 tw-border-gray-500" @click="fit('Y')">
      <svg
        t="1602558646095"
        class="fill-current"
        :class="size === 'sm' ? 'tw-w-5 tw-h-5' : 'tw-w-10 tw-h-10'"
        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1572"
      ><path d="M707.072 316.928v384h-384v-384h384z m-64 64h-256v256h256v-256z m-136-224.992l-99.584 99.552L360 208 507.072 60.928 654.144 208l-47.488 47.52-99.616-99.584z m0 712.128l99.584-99.552L654.144 816 507.072 963.072 360 816l47.488-47.488 99.584 99.552z" p-id="1573"></path></svg>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import useImageOperation from '@/hooks/useImageOperations'

export default defineComponent({
  name: 'ImageOperationFit',
  props: {
    size: {
      type: String,
      rquired: false,
      default: 'sm'
    }
  },
  setup () {
    return {
      ...useImageOperation()
    }
  }
})
</script>
