<template>
  <div v-if="display">
    <div class="background-toolbar tw-flex tw-mb-4">
      <button class="tw-w-1/2 tw-h-10 tw-bg-gray-300" @click="cancel">Cancel</button>
      <button class="tw-w-1/2 tw-h-10 tw-bg-gray-300" @click="done">Done</button>
    </div>

    <!-- scale btns -->
    <div v-if="secType === 'scale'" class="scale-btns tw-flex tw-justify-center">
      <image-operation-scale size="lg"></image-operation-scale>
    </div>
    <!-- /scale btns -->

    <div v-if="secType === 'rotate'" class="rotate-btns tw-flex tw-justify-center">
      <image-operation-rotate size="lg"></image-operation-rotate>
    </div>

    <div v-if="secType === 'move'" class="rotate-btns tw-flex tw-justify-center">
      <image-operation-move size="lg"></image-operation-move>
    </div>

    <div v-if="secType === 'flip'" class="rotate-btns tw-flex tw-justify-center">
      <image-operation-flip size="lg"></image-operation-flip>
    </div>

    <div v-if="secType === 'fit'" class="rotate-btns tw-flex tw-justify-center">
      <image-operation-fit size="lg"></image-operation-fit>
    </div>
  </div>

  <div v-else class="tw-w-full tw-h-full tw-flex tw-overflow-x-scroll tw-my-10 tw-pb-10">
    <button class="tw-w-32 tw-py-1 tw-px-6" @click="removeImage">Remove</button>
    <button class="tw-w-32 tw-py-1 tw-px-6" @click="duplicate">Duplicate</button>
    <button class="tw-w-32 tw-py-1 tw-px-6" @click="displaySecBtns('scale')">Scale</button>
    <button class="tw-w-32 tw-py-1 tw-px-6" @click="displaySecBtns('rotate')">Rotate</button>
    <button class="tw-w-32 tw-py-1 tw-px-6" @click="displaySecBtns('move')">Move</button>
    <button class="tw-w-32 tw-py-1 tw-px-6" @click="center">Align</button>
    <button class="tw-w-32 tw-py-1 tw-px-6" @click="displaySecBtns('flip')">Flip</button>
    <button class="tw-w-32 tw-py-1 tw-px-6" @click="displaySecBtns('fit')">Fit</button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import useImageOperation from '@/hooks/useImageOperations'
import ImageOperationScale from '@/components/image-operation/ImageOperationScale.vue'
import ImageOperationRotate from '@/components/image-operation/ImageOperationRotate.vue'
import ImageOperationMove from '@/components/image-operation/ImageOperationMove.vue'
import ImageOperationFlip from '@/components/image-operation/ImageOperationFlip.vue'
import ImageOperationFit from '@/components/image-operation/ImageOperationFit.vue'

export default defineComponent({
  name: 'MobileEditorPropertiesPanelImage',
  components: {
    ImageOperationScale,
    ImageOperationRotate,
    ImageOperationMove,
    ImageOperationFlip,
    ImageOperationFit
  },
  setup () {
    const display = ref(false)
    const secType = ref('')
    const materialBtn = document.querySelector('.material-panel__button') as HTMLElement
    const displaySecBtns = (type: string) => {
      secType.value = type
      display.value = true
      materialBtn.style.display = 'none'
    }
    const cancel = () => {
      display.value = false
      materialBtn.style.display = ''
    }
    const done = () => {
      display.value = false
      materialBtn.style.display = ''
    }
    return {
      displaySecBtns,
      secType,
      cancel,
      done,
      display,
      ...useImageOperation()
    }
  }
})
</script>
