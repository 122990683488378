<template>
  <teleport to="#fullscreen-container">
    <div class="image-browser fullscreen-modal tw-z-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center">

      <div class="modal-overlay tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-bg-gray-100"></div>

      <div class="wow-modal-container tw-max-w-screen-xx tw-fixed tw-w-full tw-h-full tw-z-50 tw-overflow-y-auto">

        <!-- close button -->
        <div class="modal-close tw-absolute tw-top-0 tw-right-0 tw-cursor-pointer tw-flex tw-flex-col tw-items-center tw-mt-4 tw-mr-4 tw-text-black tw-text-sm tw-z-50" @click="close">
          <svg class="fill-current tw-text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
            (Esc)
        </div>
        <!-- /close button -->

        <div class="wow-modal-content tw-w-full tw-p-4 tw-mt-10 tw-h-auto">
          <uploader class="tw-mb-4" @on-success="imageUploaded"></uploader>

          <!-- list -->
          <div class="tw-flex tw-flex-wrap tw--m-1">
            <div
              class="image-card-container tw-w-1/2 sm:tw-w-1/3 md:tw-w-1/4 lg:tw-w-1/5 xl:tw-w-1/6 xx:tw-w-1/8 tw-p-1"
               v-for="image in list" :key="image.id"
            >
              <image-card class=" tw-bg-white" :image="image" @on-delete="deleteImage(image)" @on-click="onImageClicked(image)"></image-card>
            </div>
          </div>
          <!-- /list -->
        </div>

        <pagination :pagination="pagination" @change="pageChanged"></pagination>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import Uploader from '@/components/uploader'
import ImageCard from '@/components/ImageCard.vue'
import Pagination from '@/components/Pagination.vue'
import { ImageData } from '@/store/funcs/addImage'
import { useStore } from 'vuex'
import useEscListener from '@/hooks/useEscListener'
import useImageUploaded from '@/hooks/useImageUploaded'

export default defineComponent({
  name: 'ImageBrowser',
  components: {
    Uploader,
    ImageCard,
    Pagination
  },
  emits: ['close'],
  setup (props, { emit }) {
    const { close } = useEscListener(emit)

    const store = useStore()

    const list = computed<ImageData[]>(() => {
      return store.state.image.list
    })

    const pagination = computed(() => {
      return store.state.image.pagination
    })

    const deleteImage = (image: ImageData) => {
      store.dispatch('image/deleteImage', image)
    }

    const onImageClicked = (image: ImageData) => {
      store.dispatch('designer/addImage', image)
      emit('close')
    }

    const pageChanged = (page: number) => {
      store.dispatch('image/fetchImages', page)
    }

    const { imageUploaded } = useImageUploaded()

    return {
      pageChanged,
      list,
      close,
      deleteImage,
      pagination,
      onImageClicked,
      imageUploaded
    }
  }
})
</script>

<style lang="postcss" scoped>
.fullscreen-modal {
  transition: opacity 0.25s ease;
}
</style>
