
import { defineComponent, computed } from 'vue'
import ImageProgress from '@/components/ImageProgress.vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'ImageCard',
  props: ['image'],
  components: {
    ImageProgress
  },
  emits: ['on-delete', 'on-click'],
  setup (props, { emit }) {
    const store = useStore()

    const downloadItems = computed(() => {
      return store.state.status.download.pool
    })

    const deleteImage = () => {
      emit('on-delete')
    }

    const mb = (size) => {
      return (size / 1024 / 1024).toFixed(2)
    }

    const onImageClicked = () => {
      emit('on-click')
    }

    return {
      downloadItems,
      deleteImage,
      mb,
      onImageClicked
    }
  }
})
