<template>
  <div>
    <button class="tw-border-1 tw-border-gray-500 tw-mr-4" @click="zoom(1.1)">
      <svg
        t="1602556995609"
        class="fill-current"
        :class="size === 'sm' ? 'tw-w-5 tw-h-5' : 'tw-w-10 tw-h-10'"
        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8048"
      ><path d="M474 152m8 0l60 0q8 0 8 8l0 704q0 8-8 8l-60 0q-8 0-8-8l0-704q0-8 8-8Z" p-id="8049"></path><path d="M168 474m8 0l672 0q8 0 8 8l0 60q0 8-8 8l-672 0q-8 0-8-8l0-60q0-8 8-8Z" p-id="8050"></path></svg>
    </button>
    <button class="tw-border-1 tw-border-gray-500" @click="zoom(0.9)">
      <svg
        t="1602557084542"
        class="fill-current"
        :class="size === 'sm' ? 'tw-w-5 tw-h-5' : 'tw-w-10 tw-h-10'"
        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8260"
      ><path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z" p-id="8261"></path></svg>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import useImageOperation from '@/hooks/useImageOperations'

export default defineComponent({
  name: 'ImageOperationScale',
  props: {
    size: {
      type: String,
      rquired: false,
      default: 'sm'
    }
  },
  setup () {
    return {
      ...useImageOperation()
    }
  }
})
</script>
