
import { defineComponent, ref, watchEffect } from 'vue'
import PreviewGenerationProgress from '@/components/PreviewGenerationProgress.vue'
import useGenerationProgress from '@/hooks/useGenerationProgress'
import useEscListener from '@/hooks/useEscListener'
import PreviewFullscreenList from '@/components/PreviewFullscreenList.vue'
import { Mockup } from '@/store/moduleDesign'

export default defineComponent({
  name: 'PreviewFullscreen',
  props: {
    degreeChooseable: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  components: {
    PreviewGenerationProgress,
    PreviewFullscreenList
  },
  emits: ['close', 'save-design'],
  setup(props, { emit }) {
    const { close } = useEscListener(emit)

    const { imageGroups, degrees, isDesignEdited, progress, isGenerating, generateMockups } = useGenerationProgress()

    const imageGroup = ref<Mockup[]>([])

    const degree = ref<number|undefined>(1)

    const tweakDegree = (d: number) => {
      imageGroup.value = imageGroups.value[d]
      degree.value = d
    }

    watchEffect(() => {
      const groupKeys = Object.keys(imageGroups.value)
      imageGroup.value = groupKeys.length > 0 ? imageGroups.value[groupKeys[0]] : []
    })

    const saveDesign = () => {
      emit('save-design', degree.value)
    }

    return {
      saveDesign,
      degree,
      imageGroup,
      tweakDegree,
      degrees,
      imageGroups,
      isDesignEdited,
      progress,
      isGenerating,
      generateMockups,
      close
    }
  }
})
