
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { ImageData } from '@/store/funcs/addImage'
import Uploader from '@/components/uploader'
import ImageBrowser from '@/components/ImageBrowser.vue'
import ImageProgress from '@/components/ImageProgress.vue'
import useImageUploaded from '@/hooks/useImageUploaded'

export default defineComponent({
  name: 'MaterialPanelContentMyFiles',
  components: {
    Uploader,
    ImageBrowser,
    ImageProgress
  },
  emits: ['image-clicked'],
  setup (props, { emit }) {
    const store = useStore()

    const downloadItems = computed(() => {
      return store.state.status.download.pool
    })

    const list = computed<ImageData[]>(() => {
      return store.state.image.list
    })

    const onImageClicked = img => {
      store.dispatch('designer/addImage', img)
      emit('image-clicked')
    }

    const browserVisible = ref(false)

    const { imageUploaded } = useImageUploaded()

    return {
      list,
      onImageClicked,
      imageUploaded,
      browserVisible,
      downloadItems
    }
  }
})
