
import { defineComponent } from 'vue'
import MobileToolbar from '@/components/mobile/MobileToolbar.vue'
import MobileEditor from '@/components/mobile/MobileEditor.vue'
import MobileMaterialPanel from '@/components/mobile/MobileMaterialPanel.vue'
import MobileEditorPropertiesPanel from '@/components/mobile/MobileEditorPropertiesPanel.vue'

export default defineComponent({
  name: 'MobileDesignSpace',
  components: {
    MobileToolbar,
    MobileEditor,
    MobileMaterialPanel,
    MobileEditorPropertiesPanel
  }
})
