
import { defineComponent } from 'vue'
import useGenerationProgress from '@/hooks/useGenerationProgress'

export default defineComponent({
  name: 'PreviewGenerationProgress',
  props: {
    showTitle: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  setup () {
    return {
      ...useGenerationProgress()
    }
  }
})
