<template>
  <div class="editor-propertities-panel-default tw-px-2 tw-mt-10 tw-flex tw-justify-center tw-items-center tw-text-center">
    <p class="tw-font-light">
      Click image in the canvas to edit.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EditorPropertiesPanel'
})
</script>
