import { randomStr, customizerLog } from '@/utils/helpers'
import { fabric } from 'fabric'
import { Image, IImageOptions } from 'fabric/fabric-impl'
import { IEventManager } from '../EventManager'

/**
 * meta 数据
 * @property {number} meta.id 图片的 id
 * @property {number} meta.canvasWidth 创建 ImageObject 时画布的宽度
 * @property {string} meta.sourceUrl 效果图原始链接
 * @property {string} meta.thumbUrl 效果图缩略图链接
 * @property {string} meta.sourceKey 效果图在云存储中的 key
 */
export interface ImageObjectMeta {
  id: number;
  name: string;
  canvasWidth: number;
  width: number;
  height: number;
  sourceUrl: string;
  thumbUrl: string;
  sourceKey: string;
}

export interface IImageObject {
  id: string;
  obj: Image;
  meta: ImageObjectMeta;
  /**
   * 因为实例化图片对象会有异步问题，使用该该方式实例化
   */
  build (url: string, params: IImageOptions): Promise<this>;
  move (x: number, y: number): void;
  rotate (angle: number): void;
  /**
   * 缩放图片
   */
  zoom (scale: number): void;
  center (direction?: string): void;
  fill (): void;
  fit (direction?: 'X' | 'Y'): void;
  flip (direction: 'X' | 'Y'): void;
  clone (): Promise<IImageObject>;
}

// !!fabric 中的 Image width、height表示图片加载时的原始宽高
export default class ImageObject implements IImageObject {
  obj: Image

  eventManager: IEventManager

  id = ''

  meta = {
    id: 0,
    name: '',
    canvasWidth: 0,
    width: 0,
    height: 0,
    sourceUrl: '',
    thumbUrl: '',
    sourceKey: ''
  }

  constructor (meta: ImageObjectMeta, eventManager: IEventManager) {
    this.id = randomStr(32)
    this.meta = meta
    this.eventManager = eventManager
  }

  async build (url: string, params: IImageOptions): Promise<this> {
    return new Promise((resolve, reject) => {
      try {
        fabric.Image.fromURL(
          url,
          (imgObj: Image) => {
            if (params) {
              imgObj.scaleX = params.scaleX
              imgObj.scaleY = params.scaleY
              imgObj.left = params.left
              imgObj.top = params.top
            } else {
              imgObj.scaleToWidth(this.meta.canvasWidth)
            }

            this.obj = imgObj

            resolve(this)
          },
          {
            crossOrigin: 'Anonymous',
            originX: 'center',
            originY: 'center',
            strokeWidth: -25,
            cornerSize: 15,
            transparentCorners: false
          }
        )
      } catch (err) {
        reject(err)
      }
    })
  }

  move (x: number, y: number): void {
    const params = {
      left: this.obj.left + x,
      top: this.obj.top + y
    }

    this.setParamsToObj(params)

    this.triggerModifiedEvent()
  }

  rotate (angle: number): void {
    this.setParamsToObj({
      angle: this.obj.angle + angle
    })

    this.triggerModifiedEvent()
  }

  zoom (scale: number): void {
    customizerLog(`operation: zoom ${scale}`)

    this.setParamsToObj({
      scaleX: this.obj.scaleX * scale,
      scaleY: this.obj.scaleY * scale
    })

    this.triggerModifiedEvent()
  }

  center (direction?: string): void {
    if (direction === 'vertical') {
      this.obj.centerV()
    } else if (direction === 'horiaze') {
      this.obj.centerH()
    } else {
      this.obj.center()
    }

    this.triggerModifiedEvent()
  }

  fill (): void {
    this.rotate(-this.obj.angle)
    const { width, height } = this.obj
    const canvasWidth = this.obj.canvas.width
    if (width > height) {
      customizerLog(`operation: fill, w: ${width} h: ${height}, scale height to ${canvasWidth}`)
      this.obj.scaleToHeight(canvasWidth)
    } else {
      customizerLog(`operation: fill, w: ${width} h: ${height}, scale width to ${canvasWidth}`)
      this.obj.scaleToWidth(canvasWidth)
    }
    this.obj.center()

    this.triggerModifiedEvent()
  }

  fit (direction?: 'X' | 'Y'): void {
    // reset rotate
    this.rotate(-this.obj.angle)

    const { width, height } = this.obj
    const canvasWidth = this.obj.canvas.width

    if (direction === undefined) {
      if (width > height) {
        customizerLog(`operation: fit, w: ${width} h: ${height}, scale width to ${canvasWidth}`)
        this.obj.scaleToWidth(canvasWidth)
      } else {
        customizerLog(`operation: fit, w: ${width} h: ${height}, scale height to ${canvasWidth}`)
        this.obj.scaleToHeight(canvasWidth)
      }
    } else if (direction === 'X') {
      customizerLog(`operation: fit to width: ${width} h: ${height}, scale width to ${canvasWidth}`)
      this.obj.scaleToWidth(canvasWidth)
    } else {
      customizerLog(`operation: fit to height: ${width} h: ${height}, scale height to ${canvasWidth}`)
      this.obj.scaleToHeight(canvasWidth)
    }

    this.obj.center()

    this.triggerModifiedEvent()
  }

  flip (direction: 'X' | 'Y'): void {
    this.obj.toggle(`flip${direction}` as 'flipX' | 'flipY')
    this.triggerModifiedEvent()
  }

  async clone (): Promise<IImageObject> {
    const instance = new ImageObject(this.meta, this.eventManager)
    instance.setObj(await this.cloneObj())
    return instance
  }

  protected setObj (obj: Image) {
    this.obj = obj
  }

  protected async cloneObj (): Promise<Image> {
    return new Promise<Image>((resolve) => {
      // 避免触发 fabric 的 selection:updated 事件
      this.obj.canvas.discardActiveObject()

      this.obj.clone((cloned: Image) => resolve(cloned))
    })
  }

  protected setParamsToObj (params) {
    this.obj.set(params)
  }

  protected triggerModifiedEvent () {
    this.eventManager.trigger('Objects.objectModified')
  }
}
