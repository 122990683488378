import axios from 'axios'

export default class UploadRequest {
  constructor () {
    this.axios = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL
    })
  }

  getSignature (params) {
    return this.axios.get(process.env.VUE_APP_STORAGE_ASSIGN_URL, { params })
  }

  upload (url, data, params) {
    return this.axios.request(Object.assign({
      method: 'post',
      url,
      data,
      headers: { 'Content-Type': 'multipart/form-data' }
    }, params))
  }
}
