import { Module } from 'vuex'
import { ImageData } from '@/store/funcs/addImage'
import { StoreState } from '@/store'
import actions from './moduleImageActions'
import mutations from './moduleImageMutations'

export type Pagination = {
  currentPage: number;
  from: number;
  to: number;
  lastPage: number;
  perPage: number;
  total: number;
}

export type moduleImageState = {
  list: ImageData[];
  pagination: Pagination;
}

export default {
  namespaced: true,
  state: {
    list: [],
    pagination: {
      currentPage: 1,
      lastPage: 1,
      from: 0,
      to: 0,
      total: 0,
      perPage: 30
    }
  },
  actions,
  mutations
} as Module<moduleImageState, StoreState>
