<template>
  <teleport to="#fullscreen-container">
    <div
      class="fullscreen-modal tw-z-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center"
    >
      <div
        class="modal-overlay tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-bg-white"
      ></div>

      <div
        class="wow-modal-container tw-fixed tw-w-full tw-h-full tw-z-50 tw-overflow-y-auto"
        @click="close"
      >
        <!-- close btn -->
        <div
          class="modal-close tw-absolute tw-top-0 tw-right-0 tw-cursor-pointer tw-flex tw-flex-col tw-items-center tw-mt-4 tw-mr-4 tw-text-black tw-text-sm tw-z-50"
          @click="close"
        >
          <svg
            class="fill-current tw-text-black"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            ></path>
          </svg>
        </div>
        <!-- /close btn -->

        <!-- modal content -->
        <div
          class="wow-modal-content tw-container tw-mx-full tw-mt-2 tw-h-full tw-text-left tw-pt-6 tw-bg-white"
        >
          <preview-generation-progress v-show="isGenerating" ></preview-generation-progress>

          <div class="mobile-preview-swiper-container">
            <div class="swiper-wrapper">
                <div
                  class="swiper-slide tw-bg-center tw-bg-no-repeat tw-bg-contain tw-cursor-zoom-in"
                  v-for="image in images"
                  :key="image.url"
                  :style="'background-image:url('+$process(image.url, { matchScreen: true })+')'"
                  @click="isFullscreen = true"
                >
                </div>
            </div>
            <div class="mobile-preview-swiper-pagination" id="sw-pagination"></div>
            <div class="mobile-preview-swiper-button-prev"></div>
            <div class="mobile-preview-swiper-button-next"></div>
          </div>
        </div>
        <!-- /modal content -->
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect, nextTick } from 'vue'
import Swiper, { Navigation, Pagination, SwiperOptions } from 'swiper'
import 'swiper/swiper-bundle.css'
import PreviewGenerationProgress from '@/components/PreviewGenerationProgress.vue'
import useGenerationProgress from '@/hooks/useGenerationProgress'
import useEscListener from '@/hooks/useEscListener'

Swiper.use([Navigation, Pagination])

export default defineComponent({
  name: 'MobilePreview',
  components: {
    PreviewGenerationProgress
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { close } = useEscListener(emit)

    const { images, isDesignEdited, progress, isGenerating } = useGenerationProgress()

    const swiper = ref<Swiper | null>(null)

    watchEffect(() => {
      if (!images.value.length) {
        return
      }
      nextTick(() => {
        if (swiper.value) {
          swiper.value.update()
        } else {
          nextTick(() => {
            // eslint-disable-next-line no-new
            swiper.value = new Swiper('.mobile-preview-swiper-container', {
              loop: true,
              pagination: {
                el: '.mobile-preview-swiper-pagination',
                // type: 'fraction',
                clickable: true
              },
              navigation: {
                nextEl: '.mobile-preview-swiper-button-next',
                prevEl: '.mobile-preview-swiper-button-prev'
              }
            } as SwiperOptions)
          })
        }
      })
    })
    return {
      swiper,
      images,
      isDesignEdited,
      progress,
      isGenerating,
      close
    }
  }
})
</script>

<style lang="postcss" scoped>
.fullscreen-modal {
  transition: opacity 0.25s ease;
}

::v-global(.mobile-preview-swiper-container) {
  width: 100vw;
  height: 110vw;
}

::v-global(:root) {
  --swiper-navigation-size: 26px;
}

::v-global(.mobile-preview-swiper-button-prev),
::v-global(.mobile-preview-swiper-button-next) {
  width: calc(var(--swiper-navigation-size) / 44 * 27) !important;
  height: var(--swiper-navigation-size) !important;
  margin-top: calc(-1 * var(--swiper-navigation-size) / 2) !important;
  &:after {
    font-size: var(--swiper-navigation-size) !important;
  }
}
::v-global(#sw-pagination) {
  bottom: 0;
  display: flex;
  justify-content: center;
}
</style>
