
import { defineComponent } from 'vue'
import Toolbar from '@/components/Toolbar.vue'
import EditorView from '@/components/EditorView.vue'
import EditorPropertiesPanel from '@/components/EditorPropertiesPanel.vue'

export default defineComponent({
  name: 'Editor',
  components: {
    Toolbar,
    EditorView,
    EditorPropertiesPanel
  }
})
