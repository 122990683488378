
import { IImageObject } from '@/Designer/Features/Objects/ImageObject'
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'MaterialPanelContentLayers',
  emits: ['edit-color'],
  setup (props, { emit }) {
    const store = useStore()

    const objects = computed(() => {
      return store.state.designer.objects as IImageObject[]
    })

    const color = computed(() => {
      return store.state.designer.currentColor
    })

    const activeObject = ({ id }) => {
      store.dispatch('designer/activeObject', id)
    }

    const toggleVisible = ({ id }) => {
      store.dispatch('designer/toggleObjectVisible', id)
    }

    const editColor = () => {
      emit('edit-color')
    }

    return {
      editColor,
      color,
      objects,
      activeObject,
      toggleVisible
    }
  }
})
