<template>
  <!-- 选项 -->
  <div v-show="display && !backgroundVisible" class="material-panel__options tw-absolute tw-top-0 tw-flex tw-items-end tw-w-full tw-pb-10 tw-bg-black tw-bg-opacity-50 tw-text-white" @click="close">
    <div class="wow-modal-content tw-flex tw-flex-col tw-justify-end tw-items-center tw-w-full">
      <button class="tw-p-2 tw-mb-6" @click="browserVisible = true">Add Image</button>
      <button class="tw-p-2" @click="backgroundVisible = true">Background</button>
    </div>
  </div>
  <!-- /选项 -->

  <!-- 按钮 -->
  <div v-show="!backgroundVisible" class="tw-relative tw-flex tw-justify-center">
    <div class="material-panel__button tw-absolute tw-rounded-full tw-flex tw-justify-center tw-items-center tw-bg-gray-100" style="width:2.5rem; height:2.5rem; top: -1.25rem">
      <button class="" @click="toggleDisplay">
        <svg t="1602556995609" class="tw-w-6 tw-h-6 tw-text-gray-800 tw-fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8048" ><path d="M474 152m8 0l60 0q8 0 8 8l0 704q0 8-8 8l-60 0q-8 0-8-8l0-704q0-8 8-8Z" p-id="8049"></path><path d="M168 474m8 0l672 0q8 0 8 8l0 60q0 8-8 8l-672 0q-8 0-8-8l0-60q0-8 8-8Z" p-id="8050"></path></svg>
      </button>
    </div>
  </div>
  <!-- /按钮 -->

  <image-browser v-if="browserVisible" @close="browserVisible = false"></image-browser>

  <mobile-background v-if="backgroundVisible" @close="backgroundVisible = false"></mobile-background>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import ImageBrowser from '@/components/ImageBrowser.vue'
import MobileBackground from '@/components/mobile/MobileBackground.vue'
import useEscListener from '@/hooks/useEscListener'

export default defineComponent({
  name: 'MobileMaterialPanel',
  components: {
    ImageBrowser,
    MobileBackground
  },
  setup (props, { emit }) {
    const display = ref(false)

    const toggleDisplay = () => {
      display.value = !display.value
    }

    const browserVisible = ref(false)

    const backgroundVisible = ref(false)

    const { close } = useEscListener(emit, () => {
      backgroundVisible.value = false
      display.value = false
    })

    return {
      close,
      display,
      toggleDisplay,
      browserVisible,
      backgroundVisible
    }
  }
})
</script>

<style lang="postcss">
.material-panel__options {
  height: calc(100vw + 40px);
}
</style>
