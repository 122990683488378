
import { defineComponent } from 'vue'
import useImageOperation from '@/hooks/useImageOperations'

export default defineComponent({
  name: 'ImageOperationFit',
  props: {
    size: {
      type: String,
      rquired: false,
      default: 'sm'
    }
  },
  setup () {
    return {
      ...useImageOperation()
    }
  }
})
