
import { defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'
import VSwatches from '@/components/color-picker/VSwatches.vue'
import 'vue-swatches/dist/vue-swatches.css'

export default defineComponent({
  name: 'MobileBackground',
  components: {
    VSwatches
  },
  emits: ['close'],
  setup () {
    const color = ref('')

    const store = useStore()

    watch(color, () => {
      store.dispatch('designer/setBackgroundColor', color.value)
    })

    const refSwatches = ref(null)
    const clearColor = () => {
      refSwatches.value.clearColor()
    }

    return {
      color,
      refSwatches,
      clearColor
    }
  }
})
