<template>
  <div class="editor tw-bg-gray-300" style="flex-grow: 1; width: auto;">

    <toolbar class="editor-toolbar tw-w-full"></toolbar>

    <div class="editor-content tw-flex">

      <editor-view style="flex-grow: 1;width: auto;"></editor-view>

      <editor-properties-panel></editor-properties-panel>

    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Toolbar from '@/components/Toolbar.vue'
import EditorView from '@/components/EditorView.vue'
import EditorPropertiesPanel from '@/components/EditorPropertiesPanel.vue'

export default defineComponent({
  name: 'Editor',
  components: {
    Toolbar,
    EditorView,
    EditorPropertiesPanel
  }
})
</script>

<style lang="postcss">
.editor-content {
  height: calc(100vh - 48px)
}
</style>
