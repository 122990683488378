import axios from 'axios'
import axiosRetry from 'axios-retry'
import axiosCancel from 'axios-cancel'
import { cloneDeep } from 'lodash-es'
import { IDesigner } from '@/Designer'
import { ImageData, DesignModelData } from '@/Designer/Features/SidesManager'
import { storeCurrentSide, buildBluePrintParams, BluePrintParams } from './commonFuncs'
import { CustomizerMode } from '../moduleDesign'

type MockupParams = {
  id: number;
  config: {
    width: number;
    height: number;
    multiply: {
      key: string;
    };
    softLight: {
      key: string;
    };
    overlay: {
      key: string;
    };
  };
  stickingAreas: {
    id: number;
    bluePrintId: number;
    config: {
      distortion: {
        mapping: {
          key: string;
        };
        target: {
          width: number;
          height: number;
        };
      };
      sticking: {
        width: number;
        height: number;
        left: number;
        top: number;
      };
    };
  }[];
}

type MockupsReqParams = {
  code: string;
  mode: string;
  thumb: true;
  textureDegree: number;
  bluePrints: BluePrintParams[];
  mockups: MockupParams[];
  meta: {
    handler: string;
  };
}

export type MockupRespData = {
  url: string;
  id: number;
  textureDegree: number;
  meta?: {
    handler: string;
  };
}

function buildMockupParams ({ id, config, stickingAreas }: ImageData): MockupParams {
  return { config, stickingAreas, id }
}

function buildRequestParams (designer, designModel: DesignModelData, { textureDegree, handler }): MockupsReqParams {
  // 保存当前 Playground、ObjectManager 的数据到旧的 side
  storeCurrentSide(designer, true)

  const bluePrints = designer.sidesManager.all().map(side => buildBluePrintParams(side))

  const mockups = designModel.images.map(image => buildMockupParams(image))

  const result: MockupsReqParams = {
    code: designModel.code,
    mode: 'normal',
    thumb: true,
    textureDegree,
    bluePrints,
    mockups: mockups,
    meta: { handler }
  }

  return result
}

const client = axios.create()
axiosRetry(client, { retries: 3, retryDelay: () => 100, retryCondition: () => true })
axiosCancel(client)

export class ExpiredResponseError extends Error {}

export default async function (ctx, designer: IDesigner, textureDegree?: number): Promise<any> {
  const requestId = 'mockups'
  client.cancel(requestId)

  const DEFAULT_TEXTUR_DEGREE = 2

  textureDegree = textureDegree || ctx.state.mode.toUpperCase() === CustomizerMode.CUSTOMER ? DEFAULT_TEXTUR_DEGREE : undefined

  const params = buildRequestParams(designer, cloneDeep(ctx.state.designModel), {
    handler: ctx.rootState.status.generation.handler,
    textureDegree
  })
  const region = ctx.rootState.config.bucket.region
  const hosts = JSON.parse(process.env.VUE_APP_GENERATE_MOCKUP_URL)
  // const url = hosts[region] + 'designer/dispatcher-http/'
  const url = hosts[region] + 'mockup-generation.prod/handle-generate-request-http/'
  return client.post(url, params, { requestId })
    .then(({ data }) => {
      // 识别并忽略旧的api请求结果
      if (data.meta.handler !== ctx.rootState.status.generation.handler) {
        throw new ExpiredResponseError()
      }

      const endpoints = {
        cn: 'oss-cn-shanghai',
        eur: 'oss-eu-central-1',
        us: 'oss-us-east-1',
        au: 'oss-ap-southeast-2',
        sp: 'oss-ap-southeast-1'
      }

      return data.result.map(m => {
        m.progress = 0
        m.url = m.url.replace(endpoints[region], 'oss-accelerate')
        return m
      })
    })
    .catch(err => {
      if (!axios.isCancel(err)) {
        throw err
        // ctx.dispatch('status/recordGenerateFailed', handler)
      }
      return []
    })
}
