<template>
  <div class="mobile-editor-properties-panel tw-relative">
    <keep-alive>
      <component :is="currentComponent"></component>
    </keep-alive>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import ImageObject from '@/Designer/Features/Objects/ImageObject'
import MobileEditorPropertiesPanelDefault from '@/components/mobile/MobileEditorPropertiesPanelDefault.vue'
import MobileEditorPropertiesPanelImage from '@/components/mobile/MobileEditorPropertiesPanelImage.vue'

export default defineComponent({
  name: 'MobileEditorPropertiesPanel',
  components: {
    MobileEditorPropertiesPanelImage,
    MobileEditorPropertiesPanelDefault
  },
  setup () {
    const store = useStore()
    const currentComponent = computed(() => {
      if (store.state.designer.currentObject instanceof ImageObject) {
        return 'MobileEditorPropertiesPanelImage'
      }
      return 'MobileEditorPropertiesPanelDefault'
    })

    return {
      currentComponent
    }
  }
})
</script>

<style lang="postcss">
.mobile-editor-properties-panel {
  /* height: calc(90vh - 100vw - 40px); */
}
</style>
