<template>
  <div>
    <div class="tw-flex">
      <div class="thumbs-container tw-flex-row">
        <div
          class="tw-relative"
          v-for="image in images"
          :key="image.url"
        >
          <image-progress :image="image" :list="images" title="Generating" :show-progress="false"></image-progress>
          <img
            class="tw-w-full tw-h-auto tw-cursor-pointer"
            :src="$process(image.url, { width: 90 })"
            @click="setcurrentImageUrl(image.url)"
          />
        </div>
      </div>

      <div class="image-view tw-w-auto">
        <img
          class="tw-w-full tw-h-auto"
          :src="$process(currentImageUrl, {width: 1000})"
          alt=""
          srcset=""
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Mockup } from '@/store/moduleDesign'
import { defineComponent, PropType, watchEffect, ref } from 'vue'
import ImageProgress from '@/components/ImageProgress.vue'

export default defineComponent({
  name: 'PreviewFullscreenList',
  props: {
    images: {
      required: true,
      type: Array as PropType<Mockup[]>
    }
  },
  components: {
    ImageProgress
  },
  setup(props) {
    const currentImageUrl = ref('')

    watchEffect(() => {
      currentImageUrl.value = props.images.length ? props.images[0].url : ''
    })

    const setcurrentImageUrl = (image: string) => {
      currentImageUrl.value = image
    }

    return {
      currentImageUrl,
      setcurrentImageUrl
    }
  }
})
</script>
