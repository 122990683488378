<template>
  <teleport to="#fullscreen-container">
    <div
      class="image-browser fullscreen-modal tw-z-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center"
    >
      <div
        class="modal-overlay tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-bg-gray-100"
      ></div>

      <div
        class="wow-modal-container tw-max-w-screen-xx tw-fixed tw-w-full tw-h-full tw-z-50 tw-overflow-y-auto"
      >
        <!-- close button -->
        <div
          class="modal-close tw-absolute tw-top-0 tw-right-0 tw-cursor-pointer tw-flex tw-flex-col tw-items-center tw-mt-4 tw-mr-4 tw-text-black tw-text-sm tw-z-50"
          @click="close"
        >
          <svg
            class="fill-current tw-text-black"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            ></path>
          </svg>
          (Esc)
        </div>
        <!-- /close button -->

        <div class="wow-modal-content tw-p-4 tw-mt-10 tw-h-auto tw-flex tw-flex-col tw-justify-center tw-items-center">
          <div style="max-width: 1000px">
            <p class="tw-mb-2 tw-text-center tw-font-semibold tw-text-xl">Preview Template</p>
            <a class="tw-block d tw-btn-flat tw-btn-blue tw-text-center tw-mb-4" :href="templateUrl" target="_blank">Download .psd Template</a>
            <p>Template file resolution: {{ resolution }}, DPI: {{ dpi }}</p>
            <p class="tw-font-light tw-text-sm tw-mb-1">Tips: Open the template file in Photoshop, export .png or .jpg file after designing, and upload your design to generate mockups.</p>
            <div class="tw-flex tw-justify-center tw-items-center">
              <img class="tw-w-full" :src="$process(templatePreviewUrl, { width: 1000})" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import useEscListener from '@/hooks/useEscListener'
import useTemplateData from '@/hooks/useTemplateData'

export default defineComponent({
  name: 'TemplatePreview',

  emits: ['close'],

  setup(props, { emit }) {
    const { close } = useEscListener(emit)

    return {
      ...useTemplateData(),
      close
    }
  }
})
</script>

<style lang="postcss" scoped>
.fullscreen-modal {
  transition: opacity 0.25s ease;
}
</style>
