import { useStore } from 'vuex'

type ParsedFile = {
  width: number;
  height: number;
}

const parseFileToImage = (file): Promise<ParsedFile> => {
  return new Promise((resolve) => {
    const reader = new FileReader()

    reader.onload = e => {
      const base64 = e.target.result as string
      const obj = new Image()
      obj.src = base64

      obj.onload = () => {
        resolve({
          width: obj.width,
          height: obj.height
        })
      }
    }
    reader.readAsDataURL(file)
  })
}

export default () => {
  const store = useStore()

  const imageUploaded = async ({ url, key, file }) => {
    const { width, height } = await parseFileToImage(file)
    const image = {
      url,
      key,
      width,
      height,
      size: file.size,
      name: file.name,
      ext: file.type
    }
    store.dispatch('image/createImage', image)
  }

  return {
    imageUploaded
  }
}
