import { createApp } from 'vue'
import '@/assets/css/style.pcss'
import './handleMessage'
import App from './App.vue'
import store from './store'
import { imageProcess, ImageProcesser } from './utils/image-process'
import { artworkProcess, ArtworkProcess } from './utils/artwork-process'
import { isPhone } from './utils/helpers'

declare global {
  interface Window {
    designer: any;
    canvas: any;
    _test: any;
    _wow: {
      customizer: {
        debug: boolean;
      };
    };
  }
}

window._wow = {
  customizer: {
    // debug: process.env.VUE_APP_CUSTOMIZER_DEBUG
    debug: false
  }
}

const app = createApp(App).use(store)

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $process: ImageProcesser;
    $artworkProcess: ArtworkProcess;
    isPhone: boolean;
  }
}

app.config.globalProperties.$artworkProcess = artworkProcess
app.config.globalProperties.$process = imageProcess
app.config.globalProperties.isPhone = isPhone()

app.mount('#customizer')

export default app
