
import { defineComponent, ref } from 'vue'
import ImageBrowser from '@/components/ImageBrowser.vue'
import MobileBackground from '@/components/mobile/MobileBackground.vue'
import useEscListener from '@/hooks/useEscListener'

export default defineComponent({
  name: 'MobileMaterialPanel',
  components: {
    ImageBrowser,
    MobileBackground
  },
  setup (props, { emit }) {
    const display = ref(false)

    const toggleDisplay = () => {
      display.value = !display.value
    }

    const browserVisible = ref(false)

    const backgroundVisible = ref(false)

    const { close } = useEscListener(emit, () => {
      backgroundVisible.value = false
      display.value = false
    })

    return {
      close,
      display,
      toggleDisplay,
      browserVisible,
      backgroundVisible
    }
  }
})
