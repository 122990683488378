import { onMounted } from 'vue'

export default (emit, callback?: () => void) => {
  onMounted(() => {
    document.addEventListener('keyup', (e: KeyboardEvent) => {
      const key = e.key || e.keyCode
      if (key === 'Escape' || key === 'Esc' || key === 27) {
        emit('close')
      }
    }, { once: true })
  })

  const close = (e: MouseEvent) => {
    const target = e.target as HTMLElement

    const closeEle = document.querySelector('.modal-close')
    const contentEle = document.querySelector('.wow-modal-content')

    const inCloseBtn = closeEle && closeEle.contains(target)
    const inContent = contentEle && contentEle.contains(target)

    if (inCloseBtn || !inContent) {
      callback && callback()
      emit('close')
    }
  }

  return {
    close
  }
}
