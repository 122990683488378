
import { defineComponent, computed } from 'vue'
import Uploader from '@/components/uploader'
import ImageCard from '@/components/ImageCard.vue'
import Pagination from '@/components/Pagination.vue'
import { ImageData } from '@/store/funcs/addImage'
import { useStore } from 'vuex'
import useEscListener from '@/hooks/useEscListener'
import useImageUploaded from '@/hooks/useImageUploaded'

export default defineComponent({
  name: 'ImageBrowser',
  components: {
    Uploader,
    ImageCard,
    Pagination
  },
  emits: ['close'],
  setup (props, { emit }) {
    const { close } = useEscListener(emit)

    const store = useStore()

    const list = computed<ImageData[]>(() => {
      return store.state.image.list
    })

    const pagination = computed(() => {
      return store.state.image.pagination
    })

    const deleteImage = (image: ImageData) => {
      store.dispatch('image/deleteImage', image)
    }

    const onImageClicked = (image: ImageData) => {
      store.dispatch('designer/addImage', image)
      emit('close')
    }

    const pageChanged = (page: number) => {
      store.dispatch('image/fetchImages', page)
    }

    const { imageUploaded } = useImageUploaded()

    return {
      pageChanged,
      list,
      close,
      deleteImage,
      pagination,
      onImageClicked,
      imageUploaded
    }
  }
})
