import { fabric } from 'fabric'
import { Canvas, Image, Group, Object as FabricObject } from 'fabric/fabric-impl'
import { IEventManager } from './EventManager'

export interface IPlayground {
  sourceGuideline: string;
  guidelineGroup: FabricObject | Group;
  eventManager: IEventManager;
  canvas: Canvas;
  clear(): void;
  toSVG(): string;
  setGuidelines(url: string): void;
  toggleGridlines(options?: GridlinesOptions): void;
  setBackground (color?: string): void;
}

export interface GridlinesOptions {
  color?: string;
  size?: number;
}

export default class Playground implements IPlayground {
  eventManager: IEventManager;

  canvas: Canvas;

  guidelineGroup: FabricObject | Group;

  sourceGuideline: string;

  /**
   * @param {object} args
   * @param {IEventManager} eventManager
   */
  constructor (canvas: Canvas, eventManager: IEventManager) {
    this.canvas = canvas
    this.eventManager = eventManager
  }

  /**
   * 为画布设置背景图
   */
  setBackground (color?: string): void {
    this.canvas.backgroundColor = [undefined, ''].includes(color) ? new fabric.Pattern({
      source:
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg=='
    }) : color
    this.eventManager.trigger('Playground.backgroundChanged', color)
    // 需要刷新才能显示
    window.setTimeout(() => {
      this.canvas.renderAll()
    }, 300)
  }

  /**
   * 清空画布
   */
  clear () {
    this.canvas.clear()
    this.setBackground()
    this.setGuidelines(this.sourceGuideline)
    this.canvas.renderAll()
    this.eventManager.trigger('Playground.cleared')
  }

  toSVG () {
    const cloned = fabric.util.object.clone(this.canvas)
    cloned.overlayImage = null
    return cloned.toSVG()
  }

  /**
   * 设置画布的覆盖图
   * @param {String} url
   * @returns {Promise<any>}
   */
  setGuidelines (url: string) {
    return new Promise((resolve, reject) => {
      try {
        fabric.loadSVGFromURL(url, objs => {
          // 宽高和坐标计算包括线条的宽度
          const group = fabric.util.groupSVGElements(objs)

          this.sourceGuideline = url
          this.guidelineGroup = group

          const viewBoxWidth = group.width + group.left * 2
          const scaleRate = this.canvas.width / viewBoxWidth

          this.canvas.setOverlayImage((group as Image), overlay => {
            overlay.scale(scaleRate).center()
            this.canvas.renderAll.bind(this.canvas)()
            // this.canvas.trigger('overlayImage:changed')
            resolve()
          })
        })
      } catch (err) {
        reject(err)
      }
    })
  }

  toggleGridlines (options: GridlinesOptions = {}) {
    const guidelineGroup = this.guidelineGroup as Group

    const gridlines = guidelineGroup.getObjects().find(obj => obj.name === 'gridlines')

    if (gridlines) {
      guidelineGroup.remove(gridlines)
      this.canvas.renderAll()
      return
    }

    const viewBoxWidth = this.canvas.overlayImage.width

    const gridSize = options.size || 100
    const width = viewBoxWidth
    const height = viewBoxWidth
    const left = (width % gridSize) / 2
    const top = (height % gridSize) / 2
    const lines = []
    const lineOption = {
      stroke: options.color || '#000000',
      strokeDashArray: [10, 10],
      // strokeWidth: 1,
      selectable: false
    }
    for (let i = Math.ceil(width / gridSize); i--;) {
      lines.push(
        new fabric.Line([gridSize * i, -top, gridSize * i, height], lineOption)
      )
    }
    for (let i = Math.ceil(height / gridSize); i--;) {
      lines.push(
        new fabric.Line([-left, gridSize * i, width, gridSize * i], lineOption)
      )
    }

    // eslint-disable-next-line func-call-spacing
    const oGridGroup = new fabric.Group(lines, { name: 'gridlines', left: -viewBoxWidth / 2, top: -viewBoxWidth / 2 });

    (this.guidelineGroup as Group).add(oGridGroup)

    this.canvas.renderAll()
  }
}
