type EventCallback = (...args: any[]) => void

export type AvailableEvents = 'Playground.objectSelected'|
  'Playground.backgroundChanged' |
  'Playground.objectAdded' |
  'Playground.cleared' |
  'Playground.onReady' |
  'Playground.objectRemoved' |
  'Playground.layerOrderChanged' |
  'Playground.diemensionChanged' |
  'Mockups.Generated' |
  'Objects.objectModified'

type Events = {
  [key in AvailableEvents]: EventCallback[];
}

export interface EventObserver {
  handle: (name: string, data?: any) => void;
}

export interface IEventManager {
  events: Events;
  observers: EventObserver[];
  observerd(observer: EventObserver): void;
  register(name: AvailableEvents, callback: EventCallback): void;
  trigger(name: AvailableEvents, ...args: any[]): void;
  clear(name?: AvailableEvents): void;
}

export default class EventManager implements IEventManager {
  events = {
    'Playground.objectSelected': [],
    'Playground.backgroundChanged': [],
    'Playground.objectAdded': [],
    'Playground.onReady': [],
    'Playground.cleared': [],
    'Playground.objectRemoved': [],
    'Playground.layerOrderChanged': [],
    'Playground.diemensionChanged': [],
    'Mockups.Generated': [],
    'Objects.objectModified': []
    // 'Sides.OnSetSides': [],
    // 'Designer.OnSaveBtnClicked.BrandCustomization': [],
    // 'Designer.OnSaveBtnClicked.HangTagCustomization': [],
    // 'Designer.OnSaveBtnClicked.PackageCustomization': [],
    // 'Designer.OnSaveBtnClicked.BrandReCustomization': [],
    // 'Designer.OnSaveBtnClicked.HangTagReCustomization': [],
    // 'Designer.OnSaveBtnClicked.PackageReCustomization': [],
    // 'Designer.OnSaveBtnClicked.Default': []
  }

  observers = []

  observerd (observer: EventObserver): void {
    this.observers.push(observer)
  }

  /**
   * 注册事件
   * @param {AvailableEvents} name
   * @param {EventCallback} callback
   */
  register (name: AvailableEvents, callback: EventCallback): void {
    if (!this.hasEventNamed(name)) {
      throw new Error('No event named: ' + name)
    }

    this.events[name].push(callback)
  }

  /**
   *  触发事件
   * @param {AvailableEvents} name
   * @param {any[]} args
   */
  trigger (name: AvailableEvents, ...args: any[]): void {
    if (!this.hasEventNamed(name)) {
      throw new Error('No event named: ' + name)
    }

    // eslint-disable-next-line prefer-spread
    this.events[name].forEach(callback => callback.apply(null, args))

    this.observers.forEach(observer => observer.handle(name, args))
  }

  /**
   * 清空事件监听
   * @param {AvailableEvents} [name]
   */
  clear (name?: AvailableEvents): void {
    if (!name) {
      for (const name of Object.keys(this.events)) {
        this.events[name] = []
      }
    } else {
      if (this.hasEventNamed(name)) {
        this.events[name] = []
      }
    }
  }

  /**
   * 事件是否存在
   * @param {string} name
   * @returns {boolean}
   */
  protected hasEventNamed (name: string): boolean {
    // eslint-disable-next-line no-prototype-builtins
    return this.events.hasOwnProperty(name)
  }
}
