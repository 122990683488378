import { ref } from 'vue'
import { useStore } from 'vuex'

export default function () {
  const store = useStore()

  const forward = () => {
    store.dispatch('designer/forwardCurrentImage')
  }

  const backward = () => {
    store.dispatch('designer/backwardCurrentImage')
  }

  const clear = () => {
    store.dispatch('designer/clear')
  }

  const templateVisible = ref(false)
  const displayTemplate = () => {
    templateVisible.value = true
  }

  return {
    templateVisible,
    displayTemplate,
    forward,
    backward,
    clear
  }
}
