import { ActionContext, ActionTree } from 'vuex'
import { StoreState } from '.'
import { CustomizerMode, ModuleState } from './moduleDesign'
import { IDesigner } from '../Designer'
import { GridlinesOptions } from '@/Designer/Features/Playground'
import { IImageObject } from '@/Designer/Features/Objects/ImageObject'
import { DesignModelData } from '@/Designer/Features/SidesManager'
import initDesigner from './funcs/initDesigner'
import initDesignModelConfig from './funcs/initDesignModelConfig'
import addImage, { ImageDataWithOptions } from './funcs/addImage'
import requestMockups, { ExpiredResponseError } from './funcs/requestMockups'
import { sendCompleteDesignMsg } from '@/sendMessage'
import getDesignData from './funcs/getDesignData'

let designer: null | IDesigner = null

export default {
  async completeDesign (ctx, degree?: number) {
    const design = await getDesignData(designer, ctx.state.mockups, degree)
    sendCompleteDesignMsg(design)
    ctx.commit('SET_CUSTOMIZER_VISIBLE', false)
  },

  calculatePrintQuality(ctx) {
    let quality = ''
    const image = designer.objectsManager.getActiveObj()
    if (!image) return
    const maxSide = Math.max(image.meta.width, image.meta.height)
    const psd = designer.sidesManager.getCurrent().config.psd
    const dpi = (designer.playground.canvas.width / (psd.width / psd.dpi)) / image.obj.scaleX * (maxSide / 1000)
    const result = dpi / psd.dpi
    switch (true) {
      case result >= 0.85:
        quality = 'Good'
        break
      case result >= 0.6:
        quality = 'Medium'
        break
      case result < 0.6:
        quality = 'Bad'
        break
      default:
        quality = 'N/A'
    }

    ctx.commit('SET_CURRENT_OBJECT_PRINT_QUALITY', { psd, dpi: dpi.toFixed(0), quality })
  },

  activeObject (ctx, id: string) {
    designer.objectsManager.active(id)
  },

  toggleObjectVisible (ctx, id: string) {
    designer.objectsManager.toggleVisible(id)
  },

  async generateMockups (ctx: ActionContext<ModuleState, any>, textureDegree?: number) {
    ctx.commit('SET_MOCKUPS', ctx.state.designModel.images.map(image => ({ url: image.url, id: image.id, progress: 1 })))

    ctx.dispatch('status/startGenerationProgress', {
      interval: 200,
      reset: true
    }, { root: true })

    ctx.dispatch('status/clearDesignEdited', null, { root: true })

    try {
      const mockups = await requestMockups(ctx, designer, textureDegree)
      if (!mockups.length) {
        return
      }
      ctx.commit('SET_MOCKUPS', mockups)
      ctx.dispatch('status/stopGenerationProgress', true, { root: true })
      designer.eventManager.trigger('Mockups.Generated')
    } catch (e) {
      if (e instanceof ExpiredResponseError) {
        return
      }
      ctx.dispatch('status/stopGenerationProgress', true, { root: true })
      ctx.dispatch('status/recordDesignEdited', null, { root: true })
      throw e
    }
  },

  async addImage (ctx, data: ImageDataWithOptions) {
    return addImage(ctx, designer, data)
  },

  clear () {
    designer.playground.clear()
  },

  setBackgroundColor (ctx, color) {
    designer.playground.setBackground(color)
    ctx.commit('SET_CURRENT_COLOR', color)
    const currentSide = designer.sidesManager.getCurrent()
    currentSide.backgroundColor = color
  },

  initDesignModelConfig (ctx, data: DesignModelData) {
    ctx.commit('SET_DESIGN_MODEL', data)
    ctx.commit('SET_MOCKUPS', data.images.map(image => ({ url: image.url, id: image.id, progress: 0 })))

    initDesignModelConfig(designer, data)
  },

  setObjects ({ commit }) {
    commit('SET_OBJECTS', designer.objectsManager.all())
  },

  clearObjects ({ commit }) {
    commit('SET_OBJECTS', [])
  },

  setCurrentObject ({ commit }, obj?: IImageObject | null) {
    obj = obj !== undefined ? obj : designer.objectsManager.getActiveObj()
    commit('SET_CURRENT_OBJECT', obj)
  },

  removeCurrentImage ({ commit }) {
    designer.objectsManager.removeObj(designer.objectsManager.getActiveObj())

    commit('SET_CURRENT_OBJECT', null)
  },

  render () {
    designer.playground.canvas.renderAll()
  },

  async duplicateCurrentImage ({ commit, dispatch }) {
    const currentImage: IImageObject = designer.objectsManager.getActiveObj()
    const cloned = await currentImage.clone()
    cloned.move(10, 10)
    designer.objectsManager.addObj(cloned)
    commit('SET_CURRENT_OBJECT', cloned)
    dispatch('render')
  },

  openCustomizer(ctx, mode: CustomizerMode) {
    ctx.commit('SET_CUSTOMIZER_MODE', mode)
    ctx.commit('SET_CUSTOMIZER_VISIBLE', true)

    if (!ctx.state.initialized) {
      designer = initDesigner(ctx)
      ctx.commit('SET_INITIALIZED', true)
    } else {
      // TODO: correct the can size, clear canvas
    }
  },

  forwardCurrentImage () {
    designer.objectsManager.forward(designer.objectsManager.getActiveObj())
  },

  backwardCurrentImage () {
    designer.objectsManager.backward(designer.objectsManager.getActiveObj())
  },

  toggleGridlines (ctx, options?: GridlinesOptions) {
    designer.playground.toggleGridlines(options)
  }

} as ActionTree<ModuleState, StoreState>
