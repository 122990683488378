import { ActionTree } from 'vuex'
import { StoreState } from '.'
import { DownloadItem, ModuleState, SaveDrafStatus } from './moduleStatus'
import { randomStr } from '@/utils/helpers'

export default {
  recordDesignEdited({ commit }) {
    commit('RECORD_DESIGN_EDITED')
  },

  clearDesignEdited({ commit }) {
    commit('CLEAR_DESIGN_EDITED')
  },

  startGenerationProgress({ state, commit, dispatch }, { interval, reset }) {
    if (reset) {
      commit('CLEAR_GENERATION_TIMER')
      commit('SET_GENERATION_PROGRESS', 0)
      commit('SET_GENERATION_HANDLER', randomStr())
    }

    const timer = window.setInterval(async () => {
      if (state.generation.progress < 100) {
        commit('SET_GENERATION_PROGRESS', state.generation.progress + 1)
      }

      const modify = async (sleep: number) => {
        await dispatch('stopGenerationProgress')
        dispatch('startGenerationProgress', { interval: sleep, reset: false })
      }

      switch (true) {
        case state.generation.progress >= 80:
          modify(500)
          return
        case state.generation.progress >= 92:
          modify(12000)
          return
        case state.generation.progress >= 95:
          modify(100000000)
          return
        case state.generation.progress >= 100:
          dispatch('stopGenerationProgress', true)
      }
    }, interval)
    commit('SET_GENERATION_TIMER', timer)
  },

  async stopGenerationProgress({ state, commit }, reset?: boolean) {
    window.clearInterval(state.generation.timer)
    if (reset) {
      commit('SET_GENERATION_PROGRESS', 0)
    }
  },

  appendDownloadItem(ctx, id) {
    const item: DownloadItem = { id, progress: 0 }
    ctx.commit('APPEND_DOWNLOAD_ITEM', item)
    return item
  },

  updateDownloadItemProgress(ctx, item: DownloadItem) {
    ctx.commit('UPDATE_DOWNLOAD_ITEM_PROGRESS', item)
  },

  removeDownloadItem(ctx, item: DownloadItem) {
    ctx.commit('REMOVE_DOWNLOAD_ITEM', item)
  },

  setUploaderProgress(ctx, progress: number) {
    ctx.commit('SET_UPLOADER_PROGRESS', progress)
  },

  setUploaderUploading(ctx, uploading: boolean) {
    ctx.commit('SET_UPLOADER_UPLOADING', uploading)
  },

  setSaveDrafStatus(ctx, status: SaveDrafStatus) {
    ctx.commit('SET_SAVE_DRAF_STATUS', status)
  }

} as ActionTree<ModuleState, StoreState>
