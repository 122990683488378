import { MutationTree } from 'vuex'
import { ModuleState } from './moduleConfig'

export default {
  SET_BUCKET_REGION(state, region) {
    state.bucket.region = region
  },
  SET_UUID(state, u) {
    state.uuid = u
  },
  SET_IS_SAVE_DRAF(state, isSave) {
    state.isSaveDraf = isSave
  }
} as MutationTree<ModuleState>
