<template>
  <div class="tw-relative tw-h-screen">
    <div class="tw-flex tw-m-1" >
      <button class="tw-btn-flat tw-btn-blue tw-w-full tw-mb-3" @click="browserVisible = true">View full image browser</button>
    </div>

    <uploader class="tw-mb-4 tw-m-1" @on-success="imageUploaded"></uploader>

    <div class="images-container tw-flex tw-flex-wrap tw-pb-32">
      <div
        class="tw-relative tw-w-1/3 tw-p-1"
        v-for="img in list"
        :key="img.id"
      >
        <image-progress :image="img" :list="downloadItems"></image-progress>
        <img
          class="tw-object-contain tw-w-full tw-h-full tw-bg-white tw-cursor-pointer"
          :src="$artworkProcess(img.url, img.key, 100)"
          alt="img.name"
          @click="onImageClicked(img)"
        />
      </div>
    </div>

    <image-browser v-if="browserVisible" @close="browserVisible = false"></image-browser>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { ImageData } from '@/store/funcs/addImage'
import Uploader from '@/components/uploader'
import ImageBrowser from '@/components/ImageBrowser.vue'
import ImageProgress from '@/components/ImageProgress.vue'
import useImageUploaded from '@/hooks/useImageUploaded'

export default defineComponent({
  name: 'MaterialPanelContentMyFiles',
  components: {
    Uploader,
    ImageBrowser,
    ImageProgress
  },
  emits: ['image-clicked'],
  setup (props, { emit }) {
    const store = useStore()

    const downloadItems = computed(() => {
      return store.state.status.download.pool
    })

    const list = computed<ImageData[]>(() => {
      return store.state.image.list
    })

    const onImageClicked = img => {
      store.dispatch('designer/addImage', img)
      emit('image-clicked')
    }

    const browserVisible = ref(false)

    const { imageUploaded } = useImageUploaded()

    return {
      list,
      onImageClicked,
      imageUploaded,
      browserVisible,
      downloadItems
    }
  }
})
</script>
