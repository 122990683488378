import { Module } from 'vuex'
import actions from './moduleStateActions'
import mutations from './moduleStateMutations'
import { StoreState } from '.'

export interface DownloadItem {
  id: number;
  progress: number;
}

export enum SaveDrafStatus {
  Pending,
  Saved,
  Failed,
  Saving
}

export interface ModuleState {
  uploader: {
    progress: number;
    uploading: boolean;
  };
  generation: {
    handler: string;
    progress: number;
    timer: number;
  };
  download: {
    pool: DownloadItem[];
  };
  saveDraf: {
    status: SaveDrafStatus;
  };
  isDesignEdited: boolean;
}

export default {
  namespaced: true,
  state: {
    uploader: {
      progress: 0,
      uploading: false
    },
    isDesignEdited: false,
    generation: {
      handler: '',
      progress: 0,
      timer: 0
    },
    download: {
      pool: []
    },
    saveDraf: {
      status: SaveDrafStatus.Pending
    }
  },
  mutations,
  actions
} as Module<ModuleState, StoreState>
