
import { defineComponent, ref, watchEffect, nextTick } from 'vue'
import Swiper, { Navigation, Pagination, SwiperOptions } from 'swiper'
import 'swiper/swiper-bundle.css'
import PreviewFullscreen from '@/components/PreviewFullscreen.vue'
import PreviewGenerationProgress from '@/components/PreviewGenerationProgress.vue'
import ImageProgress from '@/components/ImageProgress.vue'
import useGenerationProgress from '@/hooks/useGenerationProgress'

Swiper.use([Navigation, Pagination])

export default defineComponent({
  name: 'Preview',
  components: {
    PreviewFullscreen,
    PreviewGenerationProgress,
    ImageProgress
  },
  setup () {
    const { images, firstGroup, isDesignEdited, progress, isGenerating, generateMockups } = useGenerationProgress()

    const swiper = ref<Swiper | null>(null)

    watchEffect(() => {
      if (!images.value.length) {
        return
      }
      if (swiper.value) {
        nextTick(() => {
          window.setTimeout(() => {
            swiper.value.update()
          }, 300)
        })
      } else {
        nextTick(() => {
          swiper.value = new Swiper('.swiper-container', {
            loop: true,
            pagination: {
              el: '.swiper-pagination',
              // type: 'fraction',
              clickable: true
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },
            allowTouchMove: false
          } as SwiperOptions)
          window._test = swiper.value
        })
      }
    })

    const isFullscreen = ref(false)
    const fullscreen = () => {
      isFullscreen.value = true
    }

    const isMinimize = ref(false)
    const toggleMinimize = () => {
      isMinimize.value = !isMinimize.value
    }

    return {
      firstGroup,
      isDesignEdited,
      progress,
      isGenerating,
      generateMockups,
      isFullscreen,
      swiper,
      fullscreen,
      isMinimize,
      toggleMinimize
    }
  }
})
