<template>
  <div class="design-space tw-flex tw-w-screen tw-h-screen">
    <material-panel></material-panel>
    <editor></editor>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Editor from '@/components/Editor.vue'
import MaterialPanel from '@/components/MaterialPanel.vue'

export default defineComponent({
  name: 'DesignSpace',
  components: {
    MaterialPanel,
    Editor
  }
})
</script>

<style scoped>

</style>
