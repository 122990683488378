import store from './store'
import { CustomizerMode } from './store/moduleDesign'
import { SaveDrafStatus } from './store/moduleStatus'
import { customizerLog } from './utils/helpers'

function handleInitDesignModelMsg(data) {
  customizerLog('handleInitDesignModelMsg', data)
  store.dispatch('designer/initDesignModelConfig', data)
}

function handleSetImagesMsg(data) {
  customizerLog('handleSetImagesMsg', data)
  store.dispatch('image/setImages', data)
}

function handleOpenCustomizerMsg({ mode }: { mode?: CustomizerMode }) {
  customizerLog('handleOpenCustomizerMsg', mode || CustomizerMode.MERCHAT)
  store.dispatch('designer/openCustomizer', mode)
}

function handleImageCreatedMsg(image) {
  customizerLog('handleImageCreateMsg', image)
  store.dispatch('image/addImage', image)
}

function handleConfigMsg(data) {
  customizerLog('handleConfigMsg', data)
  store.dispatch('config/config', data)
}

function handleDrafSavedMsg() {
  store.dispatch('status/setSaveDrafStatus', SaveDrafStatus.Saved)
}

window.addEventListener('message', (e) => {
  if (e.data.source !== 'wow') {
    return
  }

  switch (e.data.type) {
    case 'drafSaved':
      handleDrafSavedMsg()
      return
    case 'config':
      handleConfigMsg(e.data.data)
      return
    case 'initDesignModel':
      handleInitDesignModelMsg(e.data.data)
      return
    case 'setImages':
      handleSetImagesMsg(e.data.data)
      return
    case 'openCustomizer':
      handleOpenCustomizerMsg(e.data.data)
      return
    case 'imageCreated':
      handleImageCreatedMsg(e.data.data)
  }
})
