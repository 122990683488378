import { ActionTree } from 'vuex'
import { StoreState } from '.'
import { moduleImageState, Pagination } from './moduleImage'
import { sendFetchImagesMsg, sendDeleteImageMsg, sendCreateImageMsg } from '@/sendMessage'
import { ImageData } from '@/store/funcs/addImage'

export default {
  fetchImages(ctx, page) {
    ctx.commit('SET_PAGE', page)
    sendFetchImagesMsg(page)
  },
  setImages(ctx, data) {
    ctx.commit('SET_LIST', data.data as ImageData[])
    ctx.commit('SET_PAGINATION', data.meta as Pagination)
  },
  deleteImage(ctx, image: ImageData) {
    ctx.commit('DELETE_IMAGE', image)
    sendDeleteImageMsg(image)
  },
  createImage(ctx, image: ImageData) {
    sendCreateImageMsg(image)
  },
  addImage(ctx, image: ImageData) {
    ctx.commit('CREATE_IMAGE', image)
    ctx.dispatch('status/setUploaderUploading', false, { root: true })
  }
} as ActionTree<moduleImageState, StoreState>
