import { IImageOptions } from 'fabric/fabric-impl'
import { IDesigner } from '@/Designer'
import ImageObject, {
  IImageObject
} from '@/Designer/Features/Objects/ImageObject'
import { artworkProcess } from '@/utils/artwork-process'
import axios from 'axios'

export interface ImageData {
  id: number;
  name: string;
  url: string;
  type: string;
  width: number;
  height: number;
  ext: string;
  size: number;
  key: string;
  thumbUrl?: string;
  createdAt?: string;
}

export type ImageDataWithOptions = ImageData & {
  params?: IImageOptions;
}

async function downloadImage(ctx, { url, key, id }: ImageData) {
  // create guarder
  const item = await ctx.dispatch('status/appendDownloadItem', id, {
    root: true
  })

  const client = axios.create()
  const { data } = await client.get(artworkProcess(url, key, 1000), {
    responseType: 'blob',
    onDownloadProgress: e => {
      const progress = Math.floor((e.loaded / e.total) * 100)
      ctx.dispatch(
        'status/updateDownloadItemProgress',
        { id, progress },
        { root: true }
      )
      if (progress >= 100) {
        ctx.dispatch('status/removeDownloadItem', item, { root: true })
      }
    }
  })

  const downloadUrl = window.URL.createObjectURL(new Blob([data]))

  return downloadUrl
}

function createImageObject(
  designer: IDesigner,
  { name, thumbUrl, url, key, width, height, id, params }: ImageDataWithOptions
): Promise<IImageObject> {
  // 铺满svg画板
  const currentSide = designer.sidesManager.getCurrent()
  const canvasWidth =
    (currentSide.canvasWidth / currentSide.config.guideLineViewWidth) * currentSide.config.cropping.width

  return new ImageObject(
    {
      id,
      name,
      canvasWidth,
      width,
      height,
      sourceUrl: url,
      thumbUrl,
      sourceKey: key
    },
    designer.eventManager
  ).build(thumbUrl, params)
}

export default async function(
  ctx,
  designer: IDesigner,
  data: ImageDataWithOptions
) {
  if (designer.sidesManager.isEmpty()) {
    throw new Error('Please init design model config first.')
  }

  // 使用缩略图
  // data.thumbUrl = imageProcess(data.url, { width: 1000 })
  data.thumbUrl = await downloadImage(ctx, data)

  // 创建图片对象
  const imgObj = await createImageObject(designer, data)

  // 添加图片对象到对象管理者
  designer.objectsManager.addObj(imgObj)

  // 新图片居中铺满svg画板
  if (!data.params) {
    imgObj.center()
  }

  return Promise.resolve()
}
