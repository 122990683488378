
import { defineComponent, ref, watch } from 'vue'
import VSwatches from '@/components/color-picker/VSwatches.vue'
import 'vue-swatches/dist/vue-swatches.css'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'MaterialPanelContentBackground',
  components: {
    VSwatches
  },
  setup () {
    const color = ref('')

    const store = useStore()

    watch(color, () => {
      store.dispatch('designer/setBackgroundColor', color.value)
    })

    return {
      color
    }
  }
})
