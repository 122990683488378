
import { defineComponent } from 'vue'
import useEscListener from '@/hooks/useEscListener'
import useTemplateData from '@/hooks/useTemplateData'

export default defineComponent({
  name: 'TemplatePreview',

  emits: ['close'],

  setup(props, { emit }) {
    const { close } = useEscListener(emit)

    return {
      ...useTemplateData(),
      close
    }
  }
})
