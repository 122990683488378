<template>
  <div id="fullscreen-container" class="tw-z-50 tw-fixed tw-top-0 tw-left-0"></div>
  <div v-show="customizerVisible" id="base-container">
    <div class="design-space tw-z-40 tw-fixed tw-top-0 tw-left-0 tw-bg-white">
      <mobile-design-space v-if="isPhone"></mobile-design-space>
      <design-space v-else></design-space>
    </div>
  </div>
</template>

<script lang="ts">
import DesignSpace from '@/components/DesignSpace.vue'
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import MobileDesignSpace from '@/components/mobile/MobileDesignSpace.vue'

export default defineComponent({
  name: 'App',
  components: {
    DesignSpace,
    MobileDesignSpace
  },
  setup () {
    const store = useStore()
    const customizerVisible = computed(() => {
      return store.state.designer.customizerVisible
    })
    return {
      customizerVisible
    }
  }
})
</script>
