<template>
  <div class="uploader-container">
    <div v-if="uploading">
      <div class="tw-relative tw-pt-1">
        <div class="tw-flex tw-mb-2 tw-items-center tw-justify-between">
          <div class="tw-text-xs tw-font-semibold tw-inline-block tw-py-1 tw-px-2 tw-uppercase tw-rounded-full tw-text-pink-600 tw-bg-pink-200">
            <span v-if="progress < 100">
              Uploading...
            </span>
            <span v-else>
              Uploaded, saving...
            </span>
          </div>
          <div class="tw-text-right">
            <span
              class="tw-text-xs tw-font-semibold tw-inline-block tw-text-pink-600"
            >
              {{ progress }}%
            </span>
          </div>
        </div>
        <div
          class="tw-overflow-hidden tw-h-2 tw-mb-4 tw-text-xs tw-flex tw-rounded tw-bg-pink-200"
        >
          <div
            :style="{ width: progress + '%' }"
            class="tw-shadow-none tw-flex tw-flex-col tw-text-center tw-whitespace-nowrap tw-text-white tw-justify-center tw-bg-pink-500"
          ></div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="tw-flex tw-w-full tw-h-auto tw-items-center tw-bg-grey-lighter"
    >
      <label
        class="tw-btn-flat tw-btn-gray tw-w-full tw-inline-flex tw-justify-center tw-items-center tw-cursor-pointer"
      >
        <svg
          class="tw-w-5 tw-h-5"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
          />
        </svg>
        <span class="tw-m-1 tw-text-base tw-leading-normal">Upload Image</span>
        <input
          type="file"
          class="tw-hidden"
          @change="handleFileSelected"
          ref="file"
        />
      </label>
    </div>
  </div>
</template>

<script>
import collect from 'collect.js'
import UploadRequest from './UploadRequest'
import { generateRandomName } from '@/utils/helpers'
import { mapActions, mapState } from 'vuex'

const uploadRequest = new UploadRequest()

export default {
  name: 'Uploader',
  data() {
    return {
      signature: {
        expire: 0,
        dir: '',
        host: '',
        policy: '',
        accessid: '',
        callback: '',
        signature: ''
      },
      url: ''
    }
  },
  computed: {
    ...mapState({
      progress: (state) => state.status.uploader.progress,
      uploading: (state) => state.status.uploader.uploading,
      region: (state) => state.config.bucket.region
    })
  },
  methods: {
    ...mapActions('status', [
      'setUploaderProgress',
      'setUploaderUploading'
    ]),
    handleFileSelected() {
      if (collect(this.$refs.file.files).isEmpty()) return

      const file = this.$refs.file.files[0]

      const ext = collect(file.name.split('.')).last()
      if (!['png', 'jpg', 'gif', 'jpeg'].includes(ext)) {
        alert('Only images with .png, .jpg, .jpeg and .gif supported.')
        return
      }

      this.$emit('on-start')

      // this.progress = 0
      // this.uploading = true
      this.setUploaderProgress(0)
      this.setUploaderUploading(true)

      return (async () => {
        const now = Date.parse(new Date()) / 1000

        if (this.signature.expire < now + 3) {
          await this.requestSignature()
        }

        try {
          const { url, key } = await this.upload(file)

          this.url = url
          // this.uploading = false

          this.$emit('on-success', { url, key, file })
        } catch (e) {
          //
        }
      })()
    },

    getDatetime() {
      const date = new Date()
      return [date.getFullYear(), date.getMonth(), date.getDate()].join('-')
    },

    async upload(file) {
      const filename = file.name

      const now = Date.parse(new Date()) / 1000

      if (this.signature.expire < now + 3) {
        await this.requestSignature()
      }

      const key =
        this.signature.dir +
        this.getDatetime() +
        '/' +
        generateRandomName(filename, 30)

      const formData = new FormData()
      const params = {
        key,
        policy: this.signature.policy,
        OSSAccessKeyId: this.signature.accessid,
        // eslint-disable-next-line @typescript-eslint/camelcase
        success_action_status: '200', // 让服务端返回200,不然，默认会返回204
        callback: this.signature.callback || '',
        signature: this.signature.signature,
        'Cache-Control': 'max-age=15552000',
        expires: new Date(Date.now() + 15552000000).toUTCString(),
        file
      }

      for (const name in params) {
        formData.append(name, params[name])
      }

      try {
        await uploadRequest.upload(this.signature.host, formData, {
          onUploadProgress: this.onUploadProgress
        })
      } catch (e) {
        this.$emit('on-cancel')
        return Promise.reject(e)
      }

      return {
        url: this.signature.host + '/' + key,
        key
      }
    },

    async requestSignature() {
      const { data } = await uploadRequest.getSignature({ dir: 'artworks/', region: this.region })
      this.signature = data.data
    },

    onUploadProgress(event) {
      // this.progress = Math.floor((event.loaded / event.total) * 100)
      // this.$emit('on-progress', this.progress, event)
      this.setUploaderProgress(Math.floor((event.loaded / event.total) * 100))
    }
  }
}
</script>
