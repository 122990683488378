
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import ImageObject from '@/Designer/Features/Objects/ImageObject'
import EditorPropertiesPanelImage from '@/components/EditorPropertiesPanelImage.vue'
import EditorPropertiesPanelDefault from '@/components/EditorPropertiesPanelDefault.vue'
import Preview from '@/components/Preview.vue'

export default defineComponent({
  name: 'EditorPropertiesPanel',
  components: {
    EditorPropertiesPanelImage,
    EditorPropertiesPanelDefault,
    Preview
  },
  setup () {
    const store = useStore()
    const currentComponent = computed(() => {
      if (store.state.designer.currentObject instanceof ImageObject) {
        return 'EditorPropertiesPanelImage'
      }
      return 'EditorPropertiesPanelDefault'
    })

    return {
      currentComponent
    }
  }
})
