import { IPlayground } from './Features/Playground'
import { IObjectManager } from './Features/ObjectsManager'
import { ISidesManager } from './Features/SidesManager'
import { IEventManager } from './Features/EventManager'

export interface IDesigner {
  eventManager: IEventManager;
  playground: IPlayground;
  objectsManager: IObjectManager;
  sidesManager: ISidesManager;
  init (eventManager: IEventManager, playground: IPlayground, objectsManager: IObjectManager, sidesManager: ISidesManager): this;
}

export default class Designer implements IDesigner {
  eventManager: IEventManager;
  playground: IPlayground;
  objectsManager: IObjectManager;
  sidesManager: ISidesManager;

  init (eventManager: IEventManager, playground: IPlayground, objectsManager: IObjectManager, sidesManager: ISidesManager): this {
    this.eventManager = eventManager

    this.playground = playground

    this.objectsManager = objectsManager

    this.sidesManager = sidesManager

    this.eventManager.trigger('Playground.onReady')

    return this
  }
}
